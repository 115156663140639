import classNames from 'classnames/bind';
import React from 'react';
import { formatBankAccount } from 'shared/utils/normalization';
import { getBankImage } from 'shared/utils/view';
import { Icon, IconName } from '../Icon';
import styleIdentifiers from './bankAccount.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BankAccount(_a) {
    var _b = _a.value, value = _b === void 0 ? '' : _b, className = _a.className, small = _a.small, image = _a.image;
    return (React.createElement("div", { className: styles('bank-account', className) },
        image && (React.createElement("div", { className: styles('bank-image') }, React.createElement("img", { src: getBankImage(image) }) || React.createElement(Icon, { name: IconName.BANK }))),
        React.createElement("span", { className: styles('iban') }, small
            ? "".concat(value.substring(0, 4), " **** ").concat(value.substring(value.length - 4))
            : formatBankAccount(value))));
}
