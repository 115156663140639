import { __assign } from "tslib";
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { isEmpty, isNonNull, isNullish } from 'remeda';
import { CompanyWebCredentialsSchema, useSaveCompanyWebCredentials } from 'shared/hooks';
import { useGetCompany } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { ActionIcon, Button, Center, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconLink, IconUnlink } from '@tabler/icons-react';
export var CompanyWeb = function () {
    var _a, _b;
    var history = useHistory();
    var companyWebCredentials = useGetCompany().company_web_credentials;
    var _c = useSaveCompanyWebCredentials(), saveCompanyWebCredentials = _c.saveCompanyWebCredentials, isSaveCompanyWebCredentialsLoading = _c.isSaveCompanyWebCredentialsLoading;
    var form = useForm({
        initialValues: {
            login: (_a = companyWebCredentials.login) !== null && _a !== void 0 ? _a : '',
            password: (_b = companyWebCredentials.password) !== null && _b !== void 0 ? _b : '',
        },
    });
    useEffect(function () {
        var _a, _b;
        form.setFieldValue('login', (_a = companyWebCredentials.login) !== null && _a !== void 0 ? _a : '');
        form.setFieldValue('password', (_b = companyWebCredentials.password) !== null && _b !== void 0 ? _b : '');
    }, [companyWebCredentials]);
    var isCompanyWebConnected = isNonNull(companyWebCredentials.login) && isNonNull(companyWebCredentials.password);
    var isSaveButtonDisabled = isNullish(form.values.login) ||
        isEmpty(form.values.login) ||
        isNullish(form.values.password) ||
        isEmpty(form.values.password);
    var handleSubmit = function (e) {
        e.preventDefault();
        var data = CompanyWebCredentialsSchema.parse(form.values);
        saveCompanyWebCredentials(data, {
            onSuccess: function () {
                accountActions.getCompany();
            },
        });
    };
    var handleReset = function () {
        saveCompanyWebCredentials({ password: null, login: null }, {
            onSuccess: function () {
                accountActions.getCompany();
            },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Group, { mb: "xl" },
            React.createElement(ActionIcon, { onClick: function () { return history.push('/settings/integrations'); }, size: "md", variant: "light" },
                React.createElement(IconArrowLeft, { stroke: 1.5 })),
            React.createElement(Text, { size: "lg", fw: "bold" }, "CompanyWeb")),
        React.createElement(Center, null,
            React.createElement(Stack, { w: "33%" },
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(TextInput, __assign({}, form.getInputProps('login'), { disabled: isCompanyWebConnected, label: "Username", placeholder: "Username", mb: "md" })),
                    React.createElement(TextInput, __assign({}, form.getInputProps('password'), { disabled: isCompanyWebConnected, label: "Password", placeholder: "Password", mb: 38 })),
                    isCompanyWebConnected ? (React.createElement(Button, { leftSection: React.createElement(IconUnlink, { stroke: 1.5 }), color: "red", w: "100%", onClick: handleReset }, "Clear")) : (React.createElement(Button, { leftSection: React.createElement(IconLink, { stroke: 1.5 }), disabled: isSaveButtonDisabled, loading: isSaveCompanyWebCredentialsLoading, w: "100%", type: "submit" }, "Save")))))));
};
