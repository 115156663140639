import { __assign, __read, __spreadArray } from "tslib";
import React, { useState } from 'react';
import { BalanceTypes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { chunk, concat, filter, flat, isEmpty, isNullish, map, pipe, sort, sum, unique, } from 'remeda';
import Amount from 'shared/components/Amount';
import { useLoadDebtorBalance, useProfile, } from 'shared/hooks';
import { ActionIcon, Box, Checkbox, Group, Loader, LoadingOverlay, MultiSelect, Pagination, Stack, Text, Title, } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { CreditNoteRow } from './CreditNoteRow';
import { DetailRow } from './DetailRow';
import { InvoiceRow } from './InvoiceRow';
import { PaymentRow } from './PaymentRow';
import styleIdentifiers from './ClientDetailTabsBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export var BalanceTab = function (_a) {
    var _b;
    var debtor = _a.debtor;
    var t = useTranslation().t;
    var filters = useForm({
        initialValues: {
            startDate: null,
            endDate: null,
            types: [],
            statuses: [],
            showSettled: true,
        },
    });
    var _c = __read(useDisclosure(false), 2), isForceUnfoldActive = _c[0], toggleForceUnfold = _c[1].toggle;
    var _d = __read(useState(1), 2), pageIndex = _d[0], setPageIndex = _d[1];
    var _e = useLoadDebtorBalance(Number(debtor.id), true), _debtorBalance = _e.debtorBalance, isDebtorBalanceLoading = _e.isDebtorBalanceLoading, isDebtorBalanceFetching = _e.isDebtorBalanceFetching, debtorBalanceError = _e.debtorBalanceError;
    var profile = useProfile();
    filters.watch('statuses', function (_a) {
        var statuses = _a.value;
        if (!isEmpty(statuses))
            filters.setFieldValue('types', ['invoice']);
    });
    filters.watch('types', function (_a) {
        var types = _a.value, previousTypes = _a.previousValue;
        if (previousTypes.includes('invoice') && !types.includes('invoice')) {
            filters.setFieldValue('statuses', []);
        }
    });
    if (isDebtorBalanceLoading && _debtorBalance == null) {
        return (React.createElement(Box, { w: "100%", h: "60vh" },
            React.createElement(LoadingOverlay, { h: "100%", visible: true })));
    }
    if (debtorBalanceError != null) {
        return React.createElement("span", null, "Error");
    }
    var debtorBalance = _debtorBalance;
    var statuses = pipe(debtorBalance.invoices, concat(debtorBalance.creditNotes), map(function (invoice) { return invoice.status; }), unique(), sort(function (a, b) { return a.localeCompare(b); }));
    var allItems = __spreadArray(__spreadArray(__spreadArray([], __read(debtorBalance.invoices.map(function (item) { return (__assign(__assign({}, item), { type: 'invoice' })); })), false), __read(debtorBalance.creditNotes.map(function (item) { return (__assign(__assign({}, item), { type: 'credit_note' })); })), false), __read(debtorBalance.payments.map(function (item) { return (__assign(__assign({}, item), { type: 'payment' })); })), false).sort(function (a, b) { return (a.issueDate.isBefore(b.issueDate) ? 1 : -1); });
    var allItemsFilteredByStatus = allItems.filter(function (item) {
        if (filters.values.statuses.length === 0)
            return true;
        if (item.type === 'payment')
            return false;
        return filters.values.statuses.includes(item.status);
    });
    var allItemsFilteredByType = allItemsFilteredByStatus.filter(function (item) {
        if (filters.values.types.length === 0)
            return true;
        return filters.values.types.includes(item.type);
    });
    var allItemsFilteredBySettledAmount = allItemsFilteredByType.filter(function (item) {
        if (filters.values.showSettled)
            return true;
        switch (item.type) {
            case 'invoice':
                return item.remainingBalance + item.remainingLateFees !== 0;
            case 'credit_note':
                return item.remainingBalance !== 0;
            default:
                return true;
        }
    });
    var allItemsFilteredByTypeAndDate = (function () {
        if (isNullish(filters.values.startDate) && isNullish(filters.values.endDate)) {
            return allItemsFilteredBySettledAmount;
        }
        return allItemsFilteredBySettledAmount.filter(function (item) {
            if (filters.values.startDate && filters.values.endDate) {
                return (item.issueDate.isSameOrAfter(filters.values.startDate) &&
                    item.issueDate.isSameOrBefore(filters.values.endDate));
            }
            if (filters.values.startDate) {
                return item.issueDate.isSameOrAfter(filters.values.startDate);
            }
            return item.issueDate.isSameOrBefore(filters.values.endDate);
        });
    })();
    var totalCreditNotesSum = debtorBalance.creditNotes.reduce(function (acc, creditNote) { return acc + creditNote.totalTvac * -1; }, 0);
    var lateFees = debtorBalance.invoices.reduce(function (acc, invoice) { return acc + invoice.remainingLateFees; }, 0);
    var totalLateFeesBilled = debtorBalance.invoices.reduce(function (acc, invoice) { return acc + invoice.lateFees; }, 0);
    var totalBilled = debtorBalance.invoices.reduce(function (acc, invoice) { return acc + invoice.totalTvac; }, 0);
    var unmatchedPaymentsSum = debtorBalance.payments.reduce(function (acc, payment) { return acc + payment.remainingBalance; }, 0);
    var matchedPaymentsSum = debtorBalance.invoices.reduce(function (acc, invoice) {
        // HACK: In some cases, we receive a payment that includes late fees, but the late fees are late
        // discarded. To avoid overcontributing to the total payments sum, we only contribute the initial total
        // of the invoice if the remaining balance is 0.
        if (invoice.remainingBalance + invoice.remainingLateFees === 0 &&
            !isEmpty(invoice.matchedPayments)) {
            return acc + invoice.totalTvac;
        }
        var total = invoice.matchedPayments.reduce(function (totalAcc, payment) { return totalAcc + (payment.isLoss ? 0 : payment.amount); }, 0);
        return acc + total;
    }, 0) +
        debtorBalance.creditNotes.reduce(function (acc, invoice) {
            var _a, _b;
            var total = (_b = (_a = invoice.payments) === null || _a === void 0 ? void 0 : _a.reduce(function (totalAcc, payment) { return totalAcc + payment.amount; }, 0)) !== null && _b !== void 0 ? _b : 0;
            return acc + total;
        }, 0);
    var totalPaymentsSum = unmatchedPaymentsSum + matchedPaymentsSum;
    var lostAmount = pipe(debtorBalance.invoices, map(function (invoice) { return invoice.matchedPayments; }), flat(), filter(function (payment) { return payment.isLoss; }), map(function (payment) { return payment.amount; }), sum());
    var remainingBalance = totalBilled + totalLateFeesBilled - totalPaymentsSum - totalCreditNotesSum;
    var Icon = isForceUnfoldActive ? IconMinus : IconPlus;
    var types = Object.values(BalanceTypes).map(function (type) { return ({
        value: type,
        label: type === BalanceTypes.invoice ? t(i18nKeys.COMMON.INVOICE) : t(i18nKeys[type.toUpperCase()]),
    }); });
    var pages = chunk(allItemsFilteredByTypeAndDate, profile.preferences.itemsPerPage);
    var getAmount = function (item) {
        var res = (function () {
            if (item.type === 'payment')
                return Number(item.remainingBalance) * -1;
            if (item.type === 'credit_note')
                return Number(item.remainingBalance);
            if (item.type === 'invoice')
                return Number(item.remainingBalance) + Number(item.remainingLateFees);
            return 0;
        })();
        return res;
    };
    var tableTotal = pipe(pages, flat(), map(getAmount), sum());
    return (React.createElement(React.Fragment, null,
        React.createElement(Stack, { mb: "xl" },
            React.createElement(Title, { ta: "center", order: 3 }, t(i18nKeys.DEBTOR.SUMMARY)),
            React.createElement(DetailRow, { left: React.createElement(Text, null, t(i18nKeys.AMOUNT_BILLED)), right: React.createElement(Text, { fw: "bold", c: "blue" },
                    React.createElement(Amount, { value: totalBilled })) }),
            React.createElement(DetailRow, { left: React.createElement(Text, null, t(i18nKeys.CLAIMED_LATE_FEES)), right: React.createElement(Text, { c: "blue", fw: "bold" },
                    React.createElement(Amount, { value: lateFees })) }),
            React.createElement(DetailRow, { left: React.createElement(Text, null, t(i18nKeys.NAV.CREDIT_NOTES)), right: React.createElement(Text, { c: "red" },
                    React.createElement(Amount, { value: totalCreditNotesSum })) }),
            React.createElement(DetailRow, { left: React.createElement(Text, null, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)), right: React.createElement(Text, { c: "red" },
                    React.createElement(Amount, { value: totalPaymentsSum })) }),
            React.createElement(DetailRow, { left: React.createElement(Text, null, t(i18nKeys.LOST_SUM)), right: React.createElement(Text, { c: "red" },
                    React.createElement(Amount, { value: lostAmount })) }),
            React.createElement(DetailRow, { left: React.createElement(Stack, { gap: 0 },
                    React.createElement(Text, null, t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)),
                    React.createElement(Text, { size: "sm", c: "gray.6" }, t(i18nKeys.WITH_FEES))), right: React.createElement(Text, { mb: 20, c: "blue", fw: "bold" },
                    React.createElement(Amount, { value: remainingBalance })) }),
            React.createElement(DetailRow, { left: React.createElement(Text, null, t(i18nKeys.REMAINING_LATE_FEES)), right: React.createElement(Text, { c: "blue", fw: "bold" },
                    React.createElement(Amount, { value: lateFees })) }),
            React.createElement(DetailRow, { left: React.createElement(Stack, { gap: 0 },
                    React.createElement(Text, null, t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE)),
                    React.createElement(Text, { size: "sm", c: "gray.6" }, t(i18nKeys.WITHOUT_FEES))), right: React.createElement(Text, { mb: 20, c: "blue", fw: "bold" },
                    React.createElement(Amount, { value: remainingBalance - lateFees })) })),
        React.createElement(Group, { mb: "xl", justify: "flex-end" },
            isDebtorBalanceFetching && React.createElement(Loader, { size: "xs" }),
            React.createElement(DatePickerInput, __assign({ miw: 110, valueFormat: "DD/MM/YY", clearable: true, placeholder: t(i18nKeys.FORM.START_DATE), popoverProps: { shadow: 'md' } }, filters.getInputProps('startDate'))),
            React.createElement(DatePickerInput, __assign({ miw: 110, valueFormat: "DD/MM/YY", clearable: true, placeholder: t(i18nKeys.FORM.END_DATE), popoverProps: { shadow: 'md' } }, filters.getInputProps('endDate'))),
            React.createElement(MultiSelect, __assign({ miw: 210, clearable: true }, filters.getInputProps('statuses'), { data: statuses, comboboxProps: { shadow: 'md' }, placeholder: isEmpty(filters.values.statuses) ? 'Status' : '' })),
            React.createElement(MultiSelect, __assign({ miw: 210, clearable: true }, filters.getInputProps('types'), { data: types, comboboxProps: { shadow: 'md' }, placeholder: isEmpty(filters.values.types) ? t(i18nKeys.CLIENT.DETAIL.TYPES) : '' })),
            React.createElement(Checkbox, __assign({}, filters.getInputProps('showSettled', { type: 'checkbox' }), { label: t(i18nKeys.CLIENT.DETAIL.SHOW_SETTLED) }))),
        React.createElement("table", { className: styles('balance-table') },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null),
                    React.createElement("th", { onClick: toggleForceUnfold },
                        React.createElement(Group, null,
                            React.createElement(ActionIcon, { variant: "transparent", onClick: toggleForceUnfold },
                                React.createElement(Icon, { color: "black" })),
                            React.createElement(Text, { fw: 600 }, t(i18nKeys.FORM.REFERENCE)))),
                    React.createElement("th", null,
                        React.createElement(Text, { fw: 600 }, t(i18nKeys.STATUS))),
                    React.createElement("th", null,
                        React.createElement(Text, { fw: 600 }, t(i18nKeys.ISSUE_DATE_SHORT))),
                    React.createElement("th", null,
                        React.createElement(Text, { fw: 600 }, t(i18nKeys.DUE_DATE_SHORT))),
                    React.createElement("th", null,
                        React.createElement(Text, { fw: 600, c: "blue" }, t(i18nKeys.DEBIT))),
                    React.createElement("th", null,
                        React.createElement(Text, { fw: 600, c: "red" }, t(i18nKeys.CREDIT))),
                    React.createElement("th", null,
                        React.createElement(Text, { fw: 600 }, t(i18nKeys.BALANCE))))),
            React.createElement("tbody", null, !isEmpty(allItemsFilteredByTypeAndDate) ? (React.createElement(React.Fragment, null, (_b = pages[pageIndex - 1]) === null || _b === void 0 ? void 0 : _b.map(function (item) {
                switch (item.type) {
                    case 'invoice': {
                        return (React.createElement(InvoiceRow
                        // @ts-ignore
                        , { 
                            // @ts-ignore
                            key: item.id, invoice: item, isForceUnfoldActive: isForceUnfoldActive }));
                    }
                    case 'credit_note': {
                        return (React.createElement(CreditNoteRow
                        // @ts-ignore
                        , { 
                            // @ts-ignore
                            key: item.id, creditNote: item, isForceUnfoldActive: isForceUnfoldActive }));
                    }
                    case 'payment': {
                        return (React.createElement(PaymentRow, { key: item.id, payment: item }));
                    }
                    default: {
                        return null;
                    }
                }
            }))) : (React.createElement("tr", null,
                React.createElement("td", { colSpan: 7, className: styles('no-result') }, t(i18nKeys.NO_RESULT))))),
            React.createElement("tfoot", null,
                React.createElement("tr", null,
                    React.createElement("td", { colSpan: 7 }),
                    React.createElement("td", null,
                        React.createElement(Text, { fw: "bold", ta: "right" },
                            React.createElement(Amount, { value: tableTotal })))))),
        React.createElement(Group, { w: "100%", justify: "flex-end", mt: "md" },
            React.createElement(Pagination, { size: "sm", total: pages.length, value: pageIndex, onChange: setPageIndex }))));
};
