import { __assign, __read } from "tslib";
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TableFooter from 'shared/components/CustomTable/TableFooter';
import { Icon, IconName } from 'shared/components/Icon';
import ThirdpartyCaseListItem from 'shared/components/ThirdpartyCaseListItem';
import { thirdPartyCaseActions } from 'store/thirdpartyCase/thirdpartycase.actions';
import styleIdentifiers from './ThirdpartyCaseTable.scss';
var styles = classNames.bind(styleIdentifiers);
export var ThirdPartyCaseTable = function (_a) {
    var debtorId = _a.debtorId;
    var t = useTranslation().t;
    var thirdpartyCases = useSelector(function (state) { return state.thirdpartyCase.list; });
    var _b = __read(useState({
        sort_order: null,
        sort_by: null,
        page_limit: null,
    }), 2), sortData = _b[0], setSortData = _b[1];
    var THIRD_PARTY_CASES_TABLE_HEADERS = [
        {
            key: 'case_type',
            title: t(i18nKeys.CASE_TYPE),
            width: 140,
        },
        {
            key: 'case_reference',
            title: t(i18nKeys.FORM.REFERENCE),
            width: 160,
        },
        {
            key: 'created_at',
            title: t(i18nKeys.CREATION_DATE),
            width: 140,
        },
        {
            key: 'updated_at',
            title: t(i18nKeys.LAST_UPDATE),
            width: 140,
        },
        {
            key: 'status',
            title: t(i18nKeys.STATUS),
            width: 120,
        },
        {
            key: 'total_tvac',
            title: t(i18nKeys.TOTAL_VAT_PRICE),
        },
        {
            key: 'remaining_balance',
            title: t(i18nKeys.BALANCE),
        },
    ];
    var getClientTPCase = function (value) {
        thirdPartyCaseActions.listPage(__assign({ debtor_id: debtorId, status: 'with_closed' }, value));
    };
    useEffect(function () {
        getClientTPCase({ page: 1 });
    }, []);
    var items = (thirdpartyCases === null || thirdpartyCases === void 0 ? void 0 : thirdpartyCases.pages) || [];
    var setPage = function (_a) {
        var selected = _a.selected;
        getClientTPCase({ page: selected + 1 });
    };
    var getOrderedInvoices = function (key) { return function () {
        var sortOrder = sortData.sort_order === 'asc' ? 'desc' : 'asc';
        getClientTPCase(__assign(__assign({}, sortData), { sort_order: sortOrder, sort_by: key }));
        setSortData(__assign(__assign({}, sortData), { sort_order: sortOrder, sort_by: key }));
    }; };
    var customKey = function (_a) {
        var case_type = _a.case_type, id = _a.id;
        return case_type + id;
    };
    var setPageLimit = function (page_limit) { return function () {
        getClientTPCase({ page: 1, page_limit: page_limit });
        setSortData(__assign(__assign({}, sortData), { page_limit: page_limit }));
    }; };
    return thirdpartyCases ? (React.createElement("div", { className: styles('thirdparty-case-table') },
        React.createElement("div", { className: styles('recovr-table') },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: styles('box-wrapper', 'small'), style: { width: 30 } },
                            React.createElement("div", { className: styles('not-sortable-head') })),
                        THIRD_PARTY_CASES_TABLE_HEADERS.map(function (item, index) { return (React.createElement("th", { key: index, className: styles(index === THIRD_PARTY_CASES_TABLE_HEADERS.length - 1 && 'last', index === 0 && 'first') }, item.key ? (React.createElement("div", { className: styles('sortable-head'), onClick: getOrderedInvoices(item.key) },
                            item.title,
                            sortData.sort_by === item.key && (React.createElement(Icon, { name: IconName.TAILDOWN, size: "15px", className: styles('arrow', sortData.sort_order === 'asc' && 'inverted') })))) : (React.createElement("div", { className: styles('not-sortable-head') }, item.title)))); }))),
                React.createElement("tbody", null, items.map(function (item) { return (React.createElement(ThirdpartyCaseListItem, { key: customKey(item), item: item, noDebtorName: true })); }))),
            items.length === 0 && React.createElement("div", { className: styles('no-result') }, t(i18nKeys.NO_RESULT))),
        thirdpartyCases.metadata && (React.createElement(TableFooter, { className: styles('table-footer'), pagination: thirdpartyCases.metadata.pagination, setPage: setPage, itemsLength: items.length, setPageLimit: setPageLimit })))) : null;
};
