import { __assign, __read, __rest, __spreadArray } from "tslib";
import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelect, MaskInput } from 'shared/io';
import { maskVat } from 'shared/utils/mask.utils';
import { TvaTypes } from './VatInput.types';
import styleIdentifiers from './VatInput.scss';
var styles = classNames.bind(styleIdentifiers);
export var VatInput = function (_a) {
    var vatRequired = _a.vatRequired, error = _a.error, noDropdownMargin = _a.noDropdownMargin, props = __rest(_a, ["vatRequired", "error", "noDropdownMargin"]);
    var className = props.className, rest = __rest(props, ["className"]);
    var t = useTranslation().t;
    var _b = useFormContext(), watch = _b.watch, setValue = _b.setValue, control = _b.control;
    var vatValue = watch(rest.name);
    var vatType = watch('tva_type');
    useEffect(function () {
        if (vatType === 'BE' && vatValue.indexOf('_') === 14 && vatValue.indexOf('0') !== 3) {
            setValue(rest.name, "BE0".concat(vatValue.substring(3, 14)));
        }
    }, [vatValue]);
    var items = __spreadArray(__spreadArray([], __read(Object.keys(TvaTypes).map(function (tvaType) {
        return { text: tvaType, value: tvaType };
    })), false), [
        { text: t(i18nKeys.OTHER), value: 'other' },
    ], false);
    return (React.createElement("div", { className: styles('vat-input', props.withBorder && 'border') },
        React.createElement(Controller, { name: "tva_type", render: function () { return (React.createElement(CustomSelect, __assign({}, rest, { keyText: "text", keyValue: "value", label: t(i18nKeys.FORM.VAT_FORMAT), selectClassName: styles(noDropdownMargin ? '' : 'dropdown'), name: "tva_type", items: items }))); } }),
        React.createElement(Controller, { control: control, name: rest.name, rules: {
                validate: function (val) {
                    return vatRequired &&
                        (val
                            ? val.indexOf('_') > -1
                                ? 'ERROR.NOT_FULLY_FILLED'
                                : true
                            : 'ERROR.FIELD_REQUIRED');
                },
            }, render: function (_a) {
                var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                return (React.createElement(MaskInput, __assign({}, values, rest, { className: className, error: error, numberOnly: !!maskVat[vatType], mask: maskVat[vatType] })));
            } })));
};
