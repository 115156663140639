import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import React, { Fragment } from 'react';
import Amount from 'shared/components/Amount';
import DateItem from 'shared/components/DateItem';
import { dateFromNow } from 'shared/utils/view';
import { showDialog, DialogShowId, DialogShowSize } from 'store/view/view.actions';
import { Icon, IconName } from '../Icon';
import TpCaseDetail from '../TpCaseDetail';
import TpCaseStatusItem from '../TpCaseStatusItem';
import debtCollectorIcon from '../InvoiceActionsDropdown/ChooseThirdPartyTypeForm/debt-collector.svg';
import styleIdentifiers from './thirdpartyCaseListItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ThirdpartyCaseListItem(_a) {
    var item = _a.item, noDebtorName = _a.noDebtorName;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var status = item.closed ? 'close' : item.started ? 'open' : 'notOpen';
    if (item.case_type == null)
        return null;
    // Sentry 15C
    var caseTypeTranslated = t(i18nKeys.THIRD_PARTY_CASES.CASE_TYPE[item.case_type.toUpperCase()]);
    var openModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.FULL,
            title: t(i18nKeys.THIRD_PARTY_CASES.MODAL_TITLE, {
                case_type: caseTypeTranslated,
                case_reference: item.case_id || item.invoice_reference,
            }),
            children: React.createElement(TpCaseDetail, { item: item }),
        });
    };
    // todo: duplicated elsewhere, extract to utils
    var getIcon = function () {
        switch (item.case_type) {
            case 'lawyer':
                return React.createElement(Icon, { name: IconName.LAW, size: "18px", className: styles('icon') });
            case 'bailiff':
                return React.createElement(Icon, { name: IconName.BALANCE, size: "18px", className: styles('icon') });
            case 'debt_collector':
                return React.createElement("img", { width: "24px", height: "24px", src: debtCollectorIcon, alt: "Debt collector" });
            case 'callcenter':
            default:
                return React.createElement(Icon, { name: IconName.PHONE_CALL, size: "18px", className: styles('icon') });
        }
    };
    return (React.createElement("tr", { className: styles('thirdparty-case-list-item'), onClick: openModal },
        React.createElement("td", { className: styles('check-box-container', 'small') }, getIcon()),
        React.createElement("td", null, caseTypeTranslated),
        React.createElement("td", { className: styles('InvoiceNumber') }, item.case_id || t(i18nKeys.THIRD_PARTY_CASES.NOT_COMMUNICATED)),
        React.createElement("td", { className: styles('Date') },
            React.createElement(DateItem, { lg: currentLang, date: item.created_at })),
        React.createElement("td", { className: styles('Date') }, dateFromNow(item.last_update, currentLang)),
        !noDebtorName && React.createElement("td", { className: styles('Client') }, item.invoice_debtor_name),
        React.createElement(Fragment, null,
            React.createElement("td", { className: styles('StatusContainer') },
                React.createElement(TpCaseStatusItem, { status: status }))),
        React.createElement("td", { className: styles('Amount') },
            React.createElement(Amount, { value: item.total_tvac })),
        React.createElement("td", { className: styles('Amount') },
            React.createElement(Amount, { value: item.total_remaining }))));
}
