import { DateTime } from 'luxon';
import moment from 'moment';
var momentToDate = function (date) { return date.toDate(); };
export var momentToDateTime = function (date) { return DateTime.fromJSDate(momentToDate(date)); };
export var momentToIsoDate = function (date) { return momentToDateTime(date).toISODate(); };
export function sortByDate(items, dateKey, order) {
    if (order === void 0) { order = 'newestFirst'; }
    var sorted = items.sort(function (a, b) {
        var dateA = moment(a[dateKey]);
        var dateB = moment(b[dateKey]);
        return dateA.valueOf() - dateB.valueOf();
    });
    return order === 'newestFirst' ? sorted.reverse() : sorted;
}
