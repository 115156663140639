import { __assign } from "tslib";
import { merge, pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { treatInvoice } from 'shared/serializer';
import { momentToIsoDate } from 'shared/utils/date';
import { getCgv } from 'shared/utils/selectors';
var NOW = DateTime.now();
export var getDefaultIssueDate = function () { return NOW; };
export var getDefaultDueDate = function (company) { var _a; return NOW.plus({ days: ((_a = getCgv(company)) === null || _a === void 0 ? void 0 : _a.minimum_payment_terms_delay) || 0 }); };
export var isNoTva = function (debtor) { return (debtor === null || debtor === void 0 ? void 0 : debtor.intracom) || (debtor === null || debtor === void 0 ? void 0 : debtor.co_contractor); };
export var getCurrency = function (company, debtor, values) {
    return (values === null || values === void 0 ? void 0 : values.currency) || (debtor === null || debtor === void 0 ? void 0 : debtor.currency) || (company === null || company === void 0 ? void 0 : company.currency);
};
export var getInvoicesForReference = function (data, references) {
    return data
        .map(function (e) { return treatInvoice(e); })
        .filter(function (e) { return references.includes(String(e.reference)); });
};
export var getInvoicesForDebtor = function (filteredInvoices, debtor) {
    return filteredInvoices.filter(function (e) { return Number(e.debtor_attributes.id) === Number(debtor.id); });
};
var getBillingLogId = function (id) {
    if (!id) {
        return undefined;
    }
    return {
        billing_log_id: id,
    };
};
var getDate = function (key, date) {
    var _a;
    if (!date) {
        return undefined;
    }
    return _a = {},
        _a[key] = momentToIsoDate(date),
        _a;
};
var getMatchedPayments = function (_a) {
    var matched_payments_attributes = _a.matched_payments_attributes;
    if (!matched_payments_attributes) {
        return undefined;
    }
    return {
        invoices_to_link: matched_payments_attributes.map(function (_a) {
            var id = _a.id;
            return id;
        }),
    };
};
export var getTvaRate = function (tax_categories, tax_category_code, noTva) {
    var _a;
    if (noTva === void 0) { noTva = false; }
    return !noTva && tax_category_code
        ? ((_a = tax_categories.find(function (it) { return it.value === tax_category_code; })) === null || _a === void 0 ? void 0 : _a.vat_value) || 0
        : 0;
};
export var getTotalHtva = function (_a) {
    var quantity = _a.quantity, discount_rate = _a.discount_rate, _b = _a.product_attributes.current_unity_price_htva, current_unity_price_htva = _b === void 0 ? 0 : _b;
    return Number(quantity) * Number(current_unity_price_htva) * (1 - (Number(discount_rate) || 0) / 100);
};
export var getTotalTvac = function (productLine, tvaRate) {
    return getTotalHtva(productLine) * (1 + Number(tvaRate) / 100);
};
var getProductLines = function (_a) {
    var product_lines_attributes = _a.product_lines_attributes;
    return {
        product_lines_attributes: product_lines_attributes.map(function (e) { return (__assign(__assign({}, e), { product_id: e.id, isInvoice: !!e.linked_invoice_id })); }),
    };
};
var getObjFormValues = function (obj) {
    var _a;
    return (__assign(__assign(__assign(__assign(__assign(__assign({}, pick(obj, ['id', 'debtor_attributes', 'comment'])), getDate('issue_date', obj.issue_date)), getDate('due_date', obj.due_date)), getBillingLogId((_a = obj === null || obj === void 0 ? void 0 : obj.billing_log_attributes) === null || _a === void 0 ? void 0 : _a.id)), getMatchedPayments(obj)), getProductLines(obj)));
};
export var getCreditNoteFormValues = function (creditNote) {
    return creditNote ? getObjFormValues(creditNote) : {};
};
export var getInvoiceFormValues = function (invoice) {
    return invoice ? getObjFormValues(invoice) : {};
};
export var getProductLine = function (noTva, data) {
    if (data === void 0) { data = {}; }
    return merge({
        quantity: 1,
        total_htva: 0,
        total_tvac: 0,
        discount_rate: 0,
        product_attributes: {
            name: '',
            tax_category_code: noTva ? '00' : '',
            current_unity_price_htva: 0,
        },
    }, data);
};
