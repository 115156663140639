import classNames from 'classnames/bind';
import { useTranslation } from 'locales/';
import { DateTime } from 'luxon';
import React from 'react';
import DateItem from 'shared/components/DateItem';
import styleIdentifiers from './CommentItem.scss';
var styles = classNames.bind(styleIdentifiers);
export var CommentItem = function (_a) {
    var comment = _a.comment;
    var currentLang = useTranslation().currentLang;
    var _b = comment.attributes, date = _b.date, author = _b.author, message = _b.comment;
    return (React.createElement("div", { className: styles('CommentItem') },
        React.createElement("div", { className: styles('comment-info') },
            React.createElement("span", { className: styles('date') },
                React.createElement(DateItem, { date: DateTime.fromISO(date).toJSDate(), lg: currentLang, format: "LLL" })),
            React.createElement("div", { className: styles('from') }, author)),
        React.createElement("div", { className: styles('comment') }, message)));
};
