import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useLocation, useParams } from 'react-router';
import { isEmpty, isNonNull, isNullish } from 'remeda';
import { useGetCompany } from 'shared/utils/selectors';
import { Box, Button, Center, Divider, Group, Loader, Stack, Text } from '@mantine/core';
import { IconArrowRight, IconExternalLink, IconPlugConnected } from '@tabler/icons-react';
import { useLoadMonitoringData } from '../../../shared/hooks/use-load-monitoring-data';
import { ClientScore } from './components/ClientScore';
import { CreditLimit } from './components/CreditLimit';
import { MonitoringStatusActions } from './components/MonitoringStatusActions';
import { UpdateMonitoringDataButton } from './components/UpdateMonitoringDataButton';
import { WarningsOverview } from './components/WarningsOverview';
export var Monitoring = function (_a) {
    var _b, _c, _d;
    var hasVatNumber = _a.hasVatNumber;
    var t = useTranslation().t;
    var history = useHistory();
    var company = useGetCompany();
    var debtorId = useParams().id;
    var _e = useLoadMonitoringData(debtorId), monitoring = _e.monitoring, isMonitoringLoading = _e.isMonitoringLoading, monitoringError = _e.monitoringError;
    var location = useLocation();
    var queryParams = new URLSearchParams(location.search);
    var isExperimentalEnabled = queryParams.get('experimental') === 'true';
    var companyWebCredentials = company.company_web_credentials;
    var isMonitoringEnabled = isNonNull(companyWebCredentials.login) && isNonNull(companyWebCredentials.password);
    if (!isMonitoringEnabled) {
        return (React.createElement(Stack, { align: "center" },
            React.createElement(Text, { size: "lg" }, t(i18nKeys.DEBTOR.MONITORING.NOT_ENABLED)),
            React.createElement(Button, { onClick: function () { return history.push('/settings/integrations'); }, leftSection: React.createElement(IconPlugConnected, { stroke: 1.5 }), rightSection: React.createElement(IconArrowRight, { stroke: 1.5 }) }, t(i18nKeys.DEBTOR.MONITORING.TO_SETTINGS))));
    }
    if (monitoringError) {
        return (React.createElement(Stack, { align: "center" },
            React.createElement(Text, { size: "lg" }, (_d = (_c = (_b = monitoringError === null || monitoringError === void 0 ? void 0 : monitoringError.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error_message) !== null && _d !== void 0 ? _d : monitoringError.message)));
    }
    if (isMonitoringLoading) {
        return (React.createElement(Center, null,
            React.createElement(Loader, null)));
    }
    if (isNullish(monitoring)) {
        return (React.createElement(Stack, { align: "center" },
            React.createElement(Text, { size: "lg" }, t(i18nKeys.DEBTOR.MONITORING.NOT_EXISTING)),
            React.createElement(Box, { w: 150, mt: "lg" },
                React.createElement(UpdateMonitoringDataButton, { hasVatNumber: hasVatNumber, debtorId: Number(debtorId) }))));
    }
    return (React.createElement(Stack, null,
        React.createElement(MonitoringStatusActions, { isExperimentalEnabled: isExperimentalEnabled, isMonitoringActive: monitoring.isMonitoringActive, updatedAt: monitoring.updatedAt, hasVatNumber: hasVatNumber, debtorId: debtorId }),
        React.createElement(Center, { mt: "lg" },
            React.createElement(Stack, { w: { base: '100%', md: '95%', lg: '90%', xl: '80%' } },
                React.createElement(Group, { justify: "space-between", align: "flex-start", gap: 20 },
                    React.createElement(Box, { w: { base: '100%', md: '45%' } },
                        React.createElement(ClientScore, { score: monitoring.score }),
                        React.createElement(Center, null,
                            React.createElement(Button, { variant: "light", component: "a", href: monitoring.reportUrl, mt: "xs", target: "_blank", rightSection: React.createElement(IconExternalLink, { size: 16, stroke: 1.5 }) }, t(i18nKeys.DEBTOR.MONITORING.FULL_REPORT)))),
                    React.createElement(Divider, { orientation: "vertical" }),
                    React.createElement(Box, { w: { base: '100%', md: '45%' } },
                        React.createElement(CreditLimit, { creditLimit: monitoring.creditLimit }))),
                !isEmpty(monitoring.warnings) && (React.createElement(React.Fragment, null,
                    React.createElement(Divider, { w: "100%", my: "xl" }),
                    React.createElement(Center, null,
                        React.createElement(WarningsOverview, { warnings: monitoring.warnings, warningsURL: monitoring.warningsUrl }))))))));
};
