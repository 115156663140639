import { __rest } from "tslib";
import classNames from 'classnames/bind';
import React from 'react';
import Amount from 'shared/components/Amount';
import MultiCurrencyData from 'shared/components/MultiCurrencyData';
import styleIdentifiers from './bankStatus.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BankStatusAmount(_a) {
    var data = _a.data, currency = _a.currency;
    var localized_total = data.localized_total, rest = __rest(data, ["localized_total"]);
    var currencies = Object.keys(rest).filter(function (e) { return e !== currency; });
    if (!currencies.length) {
        return (React.createElement(Amount, { value: localized_total, prefix: localized_total >= 0 ? '+ ' : undefined, className: styles('amount-bank') }));
    }
    return (React.createElement(Amount, { value: localized_total, prefix: localized_total >= 0 ? '+ ' : undefined, className: styles('amount-bank') },
        React.createElement(MultiCurrencyData, { data: data, prefix: "+ " })));
}
