import React from 'react';
import { ExternalMailStatus, TaskStatus } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { upperFirst } from 'lodash-es';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from 'shared/components/Card';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { useSynchroniseIntegration } from 'shared/components/Integrations';
import Tooltip from 'shared/components/Tooltip';
import { useProfile } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { formattedDate } from 'shared/utils/view';
import { ActionIcon, Button as MantineButton } from '@mantine/core';
import { styled } from '@stitches/react';
import { IconArrowRight, IconAt, IconBuildingBank, IconFileInvoice, IconListCheck, } from '@tabler/icons-react';
import styleIdentifiers from './recentActivities.scss';
var styles = classNames.bind(styleIdentifiers);
var Hover = styled('span', {
    ':hover': {
        filter: 'brightness(1.05)',
    },
});
export default function RecentActivities(_a) {
    var _b;
    var data = _a.data, accountManagerId = _a.accountManagerId;
    var _c = useTranslation(), t = _c.t, currentLang = _c.currentLang;
    var company = useSelector(function (state) { return state.account.company.data; });
    var profile = useProfile();
    var _d = useSynchroniseIntegration(), synchronize = _d.synchronize, canSynchronize = _d.canSynchronize, lastImportDate = _d.lastImportDate;
    var currentIntegration = company.tp_accounting.integration_in_use[0];
    var hasInvoicesToTreat = data.invoices_to_treat > 0;
    return (React.createElement(Card, { className: styles('RecentActivities'), title: t(i18nKeys.YOUR_ACCOUNT) },
        React.createElement("div", { className: styles('activities') },
            React.createElement("div", { className: styles('item', hasInvoicesToTreat && 'invoices-active') },
                React.createElement("div", { className: styles('content') },
                    React.createElement(ActionIcon, { component: "span", style: { pointerEvents: 'none' }, color: hasInvoicesToTreat ? 'orange.4' : 'blue.4', variant: "subtle" },
                        React.createElement(IconFileInvoice, { size: 20, stroke: 1.5 })),
                    React.createElement("div", { className: styles('text') },
                        React.createElement(HTML, { html: t(data.invoices_to_treat > 1
                                ? i18nKeys.ACTIVITIES.INVOICES_TO_PAY_ATTENTION
                                : i18nKeys.ACTIVITIES.INVOICE_TO_PAY_ATTENTION, { count: data.invoices_to_treat }) }))),
                React.createElement(Link, { to: "/invoices/to-handle".concat(accountManagerId ? "?user_id=".concat(accountManagerId) : '') },
                    React.createElement(Hover, null,
                        React.createElement(MantineButton, { justify: "space-between", fw: 500, variant: hasInvoicesToTreat ? 'gradient' : 'light', gradient: { from: 'orange.7', to: 'orange.6', deg: -90 }, rightSection: React.createElement(IconArrowRight, { size: 14 }) }, t(i18nKeys.PROCESS))))),
            company.package.can_use_tasks && (React.createElement("div", { className: styles('item', 'tasks') },
                React.createElement("div", { className: styles('content') },
                    React.createElement(ActionIcon, { component: "span", style: { pointerEvents: 'none' }, color: "blue.4", variant: "subtle" },
                        React.createElement(IconListCheck, { size: 20, stroke: 1.5 })),
                    React.createElement("div", { className: styles('text') },
                        React.createElement(HTML, { html: t(data.ongoing_tasks_count > 1
                                ? i18nKeys.DASHBOARD.ONGOING_TASKS_COUNT
                                : i18nKeys.DASHBOARD.ONGOING_TASK_COUNT, { count: data.ongoing_tasks_count }) }))),
                React.createElement(Link, { to: "/tasks?status=".concat(TaskStatus.pending, "&user_id=").concat(accountManagerId !== null && accountManagerId !== void 0 ? accountManagerId : profile.id) },
                    React.createElement(MantineButton, { justify: "space-between", fw: 500, variant: "light", rightSection: React.createElement(IconArrowRight, { size: 14 }) }, t(i18nKeys.SEE_ALL))))),
            company.bank_accounts_attributes && (React.createElement("div", { className: styles('item', 'transactions') },
                React.createElement("div", { className: styles('content') },
                    React.createElement(ActionIcon, { component: "span", style: { pointerEvents: 'none' }, color: "blue.4", variant: "subtle" },
                        React.createElement(IconBuildingBank, { size: 20, stroke: 1.5 })),
                    React.createElement("div", { className: styles('text') },
                        React.createElement(HTML, { html: t(data.payment_count > 1
                                ? i18nKeys.ACTIVITIES.UNPROCESSED_TRANSACTIONS
                                : i18nKeys.ACTIVITIES.UNPROCESSED_TRANSACTION, { count: data.payment_count }) }))),
                React.createElement(Link, { to: "/transactions/reconciliation" },
                    React.createElement(MantineButton, { justify: "space-between", fw: 500, variant: "light", rightSection: React.createElement(IconArrowRight, { size: 14 }) }, t(i18nKeys.RECONCILE))))),
            company.package.can_use_external_mails && (React.createElement("div", { className: styles('item', 'emails') },
                React.createElement("div", { className: styles('content') },
                    React.createElement(ActionIcon, { component: "span", style: { pointerEvents: 'none' }, color: "blue.4", variant: "subtle" },
                        React.createElement(IconAt, { size: 20, stroke: 1.5 })),
                    React.createElement("div", { className: styles('text') },
                        React.createElement(HTML, { html: t(data.unprocessed_emails_count > 1
                                ? i18nKeys.DASHBOARD.UNPROCESSED_EMAILS_COUNT
                                : i18nKeys.DASHBOARD.UNPROCESSED_EMAIL_COUNT, { count: data.unprocessed_emails_count }) }))),
                React.createElement(Link, { to: "/mails?status=".concat(ExternalMailStatus.unprocessed).concat(accountManagerId != null ? "&account_manager_id=".concat(accountManagerId) : '') },
                    React.createElement(MantineButton, { justify: "space-between", fw: 500, variant: "light", rightSection: React.createElement(IconArrowRight, { size: 14 }) }, t(i18nKeys.SEE_ALL_M))))),
            !!company.tp_accounting.integration_in_use.length &&
                !company.tp_accounting.automatic_sync &&
                ((_b = company["".concat(currentIntegration, "_integration")]) === null || _b === void 0 ? void 0 : _b.use_import) && (React.createElement("div", { className: styles('item', 'integration') },
                React.createElement("div", { className: styles('content') },
                    React.createElement(Icon, { name: IconName.INTEGRATION, className: styles('icon') }),
                    React.createElement("div", { className: "item-wrapper" },
                        React.createElement("div", { className: styles('text') }, t(i18nKeys.DASHBOARD.ACTIVE_INTEGRATION, {
                            text: upperFirst(currentIntegration),
                        })),
                        lastImportDate && (React.createElement("div", { className: styles('info') }, t(i18nKeys.FORM.INTEGRATION.LAST_IMPORT_THE, {
                            date: formattedDate(lastImportDate, currentLang, 'LLL'),
                        }))))),
                React.createElement("div", null,
                    React.createElement("div", { className: styles('button-container') },
                        React.createElement(Tooltip, { icon: IconName.INFO, iconClassName: styles('icon'), direction: DropdownDirection.TOP },
                            t(i18nKeys.FORM.INTEGRATION.MANUAL_SYNC_DESCRIPTION),
                            !company.tp_accounting.can_synchronise_import && (React.createElement("div", { className: styles('info') }, t(i18nKeys.FORM.INTEGRATION.NEXT_IMPORT_THE, {
                                date: formattedDate(company.tp_accounting.manual_import_possible_from, currentLang, 'LLL'),
                            })))),
                        React.createElement(Button, { disabled: !canSynchronize, onClick: synchronize, className: styles('variable'), label: t(i18nKeys.SYNCHRONIZE), noMargin: true, color: ButtonColor.WHITE, iconRight: IconName.SYNC }))))))));
}
