import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { mergeWith, subtract } from 'lodash-es';
import { clone } from 'remeda';
import { useLoadAgedBalanceCompact } from 'shared/hooks';
import { useSafeLocalizedCompactCurrencyFormatter } from 'shared/utils/normalization';
import { BaseTicker } from '../BaseTicker';
export var TotalReceivableTicker = function () {
    var t = useTranslation().t;
    var _a = useLoadAgedBalanceCompact(), agedBalanceCompact = _a.agedBalanceCompact, isAgedBalanceCompactLoading = _a.isAgedBalanceCompactLoading;
    var formatter = useSafeLocalizedCompactCurrencyFormatter(true);
    if (isAgedBalanceCompactLoading || agedBalanceCompact == null) {
        return React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.TOTAL.TITLE) });
    }
    var netAgedBalance = mergeWith(clone(agedBalanceCompact.debits), agedBalanceCompact.credits, subtract);
    var totalReceivable = netAgedBalance.notDue +
        netAgedBalance['0'] +
        netAgedBalance['30'] +
        netAgedBalance['60'] +
        netAgedBalance['90'];
    return (React.createElement(BaseTicker, { label: t(i18nKeys.ANALYTICS.TICKERS.TOTAL.TITLE), value: formatter.format(totalReceivable), suffix: "\u20AC", url: "analytics/aged-balance" }));
};
