import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import React from 'react';
import { Button, ButtonColor } from 'shared/io';
import styleIdentifiers from './ExternalMailDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ExternalMailReplyAction = function (_a) {
    var _b = _a.disabled, disabled = _b === void 0 ? true : _b, onSubmit = _a.onSubmit;
    var t = useTranslation().t;
    return (React.createElement("div", { className: styles('info-right') },
        React.createElement(Button, { noShadow: true, color: ButtonColor.GREEN, label: t(i18nKeys.SUBMIT), noMargin: true, disabled: disabled, small: true, onClick: onSubmit })));
};
