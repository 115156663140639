import { __read } from "tslib";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { isDefined } from 'remeda';
import { isProduction } from 'shared/utils';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { useProfile } from '../shared/hooks/use-enforce-profile';
export function useInitialiseJune() {
    var profile = useProfile();
    var location = useLocation();
    var _a = __read(useState(), 2), analytics = _a[0], setAnalytics = _a[1];
    useEffect(function () {
        (function () {
            var response = AnalyticsBrowser.load({ writeKey: '0lcmuQTLGBzoKhpD' }, { initialPageview: false });
            setAnalytics(response);
        })();
    }, []);
    // Note: This hook is accessed from PrivateLoader, before the invariant on thenprofile is guaranteed
    // As a result, the type of Profile in this context is actually Profile | undefined and we use safe navigation.
    useEffect(function () {
        if (!isProduction())
            return;
        if (profile && analytics)
            analytics.identify(profile === null || profile === void 0 ? void 0 : profile.id.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDefined(analytics), profile === null || profile === void 0 ? void 0 : profile.id]);
    useEffect(function () {
        if (!isProduction())
            return;
        if (profile && analytics)
            analytics.page(undefined, location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDefined(analytics), location]);
    return analytics;
}
