import { __assign, __read } from "tslib";
import React, { useState } from 'react';
import { TasksList } from 'app/Private/Tasks/TasksList/TasksList';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Monitoring } from 'modules/Debtor/monitoring';
import { useHistory } from 'react-router';
import { isEmpty, isNonNull } from 'remeda';
import Card from 'shared/components/Card';
import Tabs from 'shared/components/Tabs';
import { useGetCompany } from 'shared/utils/selectors';
import { TabInfo } from './TabInfo/TabInfo';
import { ThirdPartyCaseTable } from './ThirdpartyCaseTable/ThirdpartyCaseTable';
import { BalanceTab } from './BalanceTab';
import { ClientDetailTabsTimeline } from './ClientDetailTabsTimeline';
import styleIdentifiers from './ClientDetail.scss';
var styles = classNames.bind(styleIdentifiers);
export var ClientDetailTabs = function (props) {
    var t = useTranslation().t;
    var history = useHistory();
    var company = useGetCompany();
    var isTasksModuleEnabledInPackage = company.package.can_use_tasks;
    var debtor = props.debtor;
    var id = debtor.id, _a = debtor.attributes, _b = _a.tp_cases_count, tp_cases_count = _b === void 0 ? 0 : _b, vat_number = _a.vat_number;
    var tabItems = [
        {
            title: React.createElement("h3", null, t(i18nKeys.CLIENT.GENERAL_INFO)),
            path: "/clients/".concat(id),
            children: React.createElement(TabInfo, __assign({}, props)),
        },
        {
            title: React.createElement("h3", null, t(i18nKeys.DASHBOARD.BALANCE_DETAIL)),
            path: "/clients/".concat(id, "/balance"),
            children: React.createElement(BalanceTab, __assign({}, props)),
        },
        {
            title: React.createElement("h3", null, t(i18nKeys.INVOICE.ACTIVITIES)),
            path: "/clients/".concat(id, "/timeline"),
            children: React.createElement(ClientDetailTabsTimeline, __assign({}, props)),
        },
    ];
    if (isTasksModuleEnabledInPackage) {
        tabItems.push({
            title: React.createElement("h3", null, t(i18nKeys.NAV.TASKS)),
            path: "/clients/".concat(id, "/tasks"),
            children: (React.createElement("div", null,
                React.createElement(TasksList, __assign({}, props)))),
        });
    }
    if (tp_cases_count > 0) {
        tabItems.push({
            title: (React.createElement("div", { className: styles('tab-header-item') },
                React.createElement("h3", null, t(i18nKeys.THIRD_PARTY_CASES.CURRENT_CASES)))),
            path: "/clients/".concat(id, "/cases"),
            children: React.createElement(ThirdPartyCaseTable, { debtorId: id }),
        });
    }
    tabItems.push({
        title: React.createElement("h3", null, t(i18nKeys.DEBTOR.MONITORING.TAB_TITLE)),
        path: "/clients/".concat(id, "/monitoring"),
        children: React.createElement(Monitoring, { hasVatNumber: isNonNull(vat_number) && !isEmpty(vat_number) }),
    });
    var _c = __read(useState(tabItems.map(function (e) { return e.path; }).indexOf(location.pathname)), 2), tabIndex = _c[0], setTabIndex = _c[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Tabs, { className: styles('workflows__tabs'), tabIndex: tabIndex, items: tabItems.map(function (e) { return (React.createElement(React.Fragment, null, e.title)); }), noBody: true, onChange: function (e) {
                setTabIndex(e);
                history.push(tabItems[e].path + location.search);
            } }),
        tabItems.map(function (_a, i) {
            var title = _a.title, children = _a.children, path = _a.path;
            return tabIndex === i && (React.createElement(Card, { key: "tab-".concat(title), noRadiusTop: true, noPadding: path.includes('/cases'), noHead: true, noBorder: true }, children));
        })));
};
