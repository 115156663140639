import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { motion } from 'framer-motion';
import { i18nKeys, useTranslation } from 'locales/';
import { UpdatedAt } from 'modules/Analytics/components';
import { isEmpty, isNonNull } from 'remeda';
import { useLoadAgedBalanceCompact, useLoadLightUsers, useProfile } from 'shared/hooks';
import { useIsFirstVisit } from 'shared/hooks/utils';
import { PageTitle } from 'shared/layout/PageTitle';
import { useGetDashboard } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { Grid, Group, Select, Title } from '@mantine/core';
import { IconUser } from '@tabler/icons-react';
import { BalanceGraphic } from './BalanceGraphic';
import BankStatus from './BankStatus';
import { DashboardChartLoader } from './DashboardChartLoader';
import { OutstandingGraphic } from './OutstandingGraphic';
import RecentActivities from './RecentActivities';
import styleIdentifiers from './dashboard.scss';
var styles = classNames.bind(styleIdentifiers);
var staggerContainer = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.02,
        },
    },
};
var staggerItem = {
    hidden: { y: 20 },
    show: { y: 0 },
};
export var Dashboard = function () {
    var dashboardData = useGetDashboard();
    useEffect(function () {
        accountActions.dashboard();
    }, []);
    if (dashboardData === null)
        return null;
    return React.createElement(DashboardInner, { data: dashboardData });
};
export var DashboardInner = function (_a) {
    var data = _a.data;
    var t = useTranslation().t;
    var profile = useProfile();
    var _b = useLoadLightUsers().users, users = _b === void 0 ? [] : _b;
    var _c = useLoadAgedBalanceCompact(), agedBalanceCompact = _c.agedBalanceCompact, isAgedBalanceCompactLoading = _c.isAgedBalanceCompactLoading;
    var _d = __read(useState(), 2), accountManagerId = _d[0], setAccountManagerId = _d[1];
    var isFirstVisit = useIsFirstVisit('dashboard');
    useEffect(function () {
        if (isNonNull(accountManagerId)) {
            accountActions.dashboard({
                noFeedback: true,
                data: { account_manager_id: accountManagerId },
            });
        }
    }, [accountManagerId]);
    // Set the initial and animate states based on isFirstVisit
    var initial = isFirstVisit ? 'hidden' : 'show';
    return (React.createElement("div", { className: styles('Dashboard') },
        React.createElement(motion.div, { variants: staggerContainer, initial: initial, animate: "show" },
            React.createElement(motion.div, { variants: staggerItem },
                React.createElement(PageTitle, null,
                    t(i18nKeys.COMMON.DASHBOARD),
                    React.createElement(PageTitle.Actions, null, (profile.isAdmin || profile.roles.includes('all_invoices')) && (React.createElement(Select, { w: 235, comboboxProps: { shadow: 'sm' }, placeholder: t(i18nKeys.ACCOUNT_MANAGER), clearable: true, disabled: isEmpty(users), leftSection: React.createElement(IconUser, null), onChange: function (value) {
                            return setAccountManagerId(value == null ? undefined : Number(value));
                        }, data: users.map(function (_a) {
                            var name = _a.name, id = _a.id;
                            return ({ value: id.toString(), label: name.full });
                        }) }))))),
            React.createElement(Grid, { style: { overflow: 'visible' } },
                React.createElement(Grid.Col, { span: profile.isAdmin ? 7 : 12 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem },
                        React.createElement(RecentActivities, { accountManagerId: accountManagerId, data: data.recent_activities }))),
                profile.isAdmin && (React.createElement(Grid.Col, { span: 5 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem },
                        React.createElement(BankStatus, { data: data.financial_data })))),
                React.createElement(Grid.Col, { span: 12, pt: "lg" },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem },
                        React.createElement(Group, { justify: "space-between" },
                            React.createElement(Title, { order: 3 }, "Analytics"),
                            React.createElement(UpdatedAt, null)))),
                React.createElement(Grid.Col, { span: 5 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem }, isAgedBalanceCompactLoading ? (React.createElement(DashboardChartLoader, { titleKey: i18nKeys.DASHBOARD.CUSTOMER_RECEIVABLES.TITLE })) : (React.createElement(OutstandingGraphic, { agedBalanceCompact: agedBalanceCompact, accountManagerId: accountManagerId })))),
                React.createElement(Grid.Col, { span: 7 },
                    React.createElement(motion.div, { style: { height: '100%' }, variants: staggerItem }, isAgedBalanceCompactLoading ? (React.createElement(DashboardChartLoader, { titleKey: i18nKeys.DASHBOARD.DELAY_GRAPHIC.TITLE })) : (React.createElement(BalanceGraphic, { agedBalanceCompact: agedBalanceCompact }))))))));
};
