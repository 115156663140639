import { __assign } from "tslib";
import React from 'react';
import { Card as MantineCard, Group, LoadingOverlay, Stack, Text } from '@mantine/core';
import { styled } from '@stitches/react';
var Card = styled('div', {
    userSelect: 'none',
    transition: 'all ease-in-out 150ms',
    flexGrow: 1,
    variants: {
        clickable: {
            true: {
                '&:hover': {
                    transform: 'translateY(-2px)',
                },
            },
        },
    },
});
export var BaseTicker = function (_a) {
    var label = _a.label, value = _a.value, suffix = _a.suffix, url = _a.url;
    if (value == null) {
        return (React.createElement(MantineCard, { radius: "md", shadow: "sm", p: "md", h: "110px", w: "170px" },
            React.createElement(LoadingOverlay, { visible: true })));
    }
    return (React.createElement(Card, { clickable: url != null },
        React.createElement(MantineCard, __assign({ radius: "md", shadow: "sm", p: "md", style: {} }, (url != null ? { component: 'a', href: url, target: '_blank' } : {})),
            React.createElement(Stack, { gap: "xs" },
                React.createElement(Text, { ff: "Roboto", c: "gray.5", size: "sm", fw: "bold" }, label),
                React.createElement(Group, { gap: "5px", align: "end", ff: "Roboto" },
                    React.createElement(Text, { size: "36px", c: "blue", fw: "bold" }, value),
                    suffix != null && (React.createElement(Text, { size: "28px", c: "dimmed", tt: "uppercase", fw: "bold" }, suffix)))))));
};
