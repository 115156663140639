import { __assign, __read, __rest } from "tslib";
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Card from 'shared/components/Card';
import { Button, DateSelector, Input, RadioButton, SwitchInput } from 'shared/io';
import { StepsCalculationDebtor } from './StepsCalculationDebtor';
import { StepsCalculationInvoices } from './StepsCalculationInvoices';
import { StepsCalculationWorkflow } from './StepsCalculationWorkflow';
import styleIdentifiers from './StepsCalculation.scss';
var styles = classNames.bind(styleIdentifiers);
export var StepsCalculation = function (_a) {
    var onSubmit = _a.onSubmit, initialValues = _a.initialValues, debtorId = _a.debtorId, invoiceIds = _a.invoiceIds;
    var t = useTranslation().t;
    var defaultValues = __assign(__assign({}, initialValues), { debtor_id: debtorId, invoice_ids: invoiceIds });
    var _b = __read(useState(), 2), currentDebtor = _b[0], setCurrentDebtor = _b[1];
    var _c = __read(useState(), 2), currentWorkflow = _c[0], setCurrentWorkflow = _c[1];
    var form = useForm({
        shouldUnregister: true,
        defaultValues: defaultValues,
    });
    var register = form.register, formState = form.formState, handleSubmit = form.handleSubmit, control = form.control, setValue = form.setValue, watch = form.watch, trigger = form.trigger;
    var _d = __read(useState((initialValues === null || initialValues === void 0 ? void 0 : initialValues.deadline_amount) ? 'amount' : 'count'), 2), deadlineType = _d[0], setDeadlineType = _d[1];
    var today = DateTime.now();
    var tomorrow = today.plus({ day: 1 });
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('step-calculation-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement(Card, { title: t(i18nKeys.DETAILS) },
                React.createElement("div", { className: styles('grid-row') },
                    React.createElement("div", { className: styles('content', 'col-12') },
                        React.createElement(RadioButton, { className: styles('radio-buttons'), noMargin: true, onChange: function (value) {
                                setDeadlineType(value);
                            }, value: deadlineType, name: "deadline_type", items: [
                                {
                                    value: 'count',
                                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT),
                                },
                                {
                                    value: 'amount',
                                    label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT),
                                },
                            ] })),
                    deadlineType === 'count' && (React.createElement("div", { className: styles('content', 'col-4') },
                        React.createElement(Input, { register: register('deadline_count', {
                                required: true,
                                min: 1,
                            }), errorMessage: get(formState.errors, 'deadline_count'), noMargin: true, withBorder: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_COUNT), type: "number", min: 1, step: "1" }))),
                    deadlineType === 'amount' && (React.createElement("div", { className: styles('content', 'col-4') },
                        React.createElement(Input, { register: register('deadline_amount', {
                                required: true,
                                min: 0,
                            }), errorMessage: get(formState.errors, 'deadline_amount'), noMargin: true, withBorder: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DEADLINE_AMOUNT), type: "number", step: "0.01" }))),
                    React.createElement("div", { className: styles('col-4') },
                        React.createElement(DateSelector, { required: true, name: "due_date", className: styles('input'), withBorder: true, minDate: tomorrow.toJSDate(), placeholder: tomorrow.toFormat('dd/MM/yy'), noMargin: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DUE_DATE), handleChange: function (value) {
                                setValue('due_date', value);
                                trigger('due_date');
                            } })),
                    React.createElement("div", { className: styles('content', 'col-4') },
                        React.createElement(Input, { register: register('delay', { required: true, min: 1 }), errorMessage: get(formState.errors, 'delay'), noMargin: true, min: 1, withBorder: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.DELAY), type: "number", step: "1", placeholder: t(i18nKeys.DAYS, { count: 30 }) })),
                    React.createElement("div", { className: styles('content', 'col-6') },
                        React.createElement(StepsCalculationDebtor, { setCurrentDebtor: function (d) {
                                setCurrentDebtor(d);
                                trigger('debtor_id');
                            } })),
                    React.createElement("div", { className: styles('content', 'col-6') },
                        React.createElement(StepsCalculationWorkflow, { currentWorkflow: currentWorkflow, setCurrentWorkflow: setCurrentWorkflow }))),
                watch('debtor_id') && (React.createElement("div", { className: styles('content', 'col-12') },
                    React.createElement(StepsCalculationInvoices, { currentDebtor: currentDebtor }))),
                React.createElement("div", { className: styles('content', 'col-12') },
                    React.createElement(Controller, { control: control, name: "with_fees", render: function (_a) {
                            var _b = _a.field, ref = _b.ref, values = __rest(_b, ["ref"]);
                            return (React.createElement(SwitchInput, __assign({}, values, { className: styles('switch-input'), inversed: true, noMargin: true, label: t(i18nKeys.WITH_FEES), noShadow: true })));
                        } })),
                React.createElement("div", { className: styles('content', 'col-12', 'button-wrapper') },
                    React.createElement(Button, { type: "submit", noMargin: true, label: t(i18nKeys.FORM.PAYMENT_PLAN.STEP_CALCULATION.CALCULATE) }))))));
};
