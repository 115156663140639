var _a;
export var Integration;
(function (Integration) {
    Integration["YUKI"] = "Yuki";
    Integration["HORUS"] = "Horus";
    Integration["BILLIT"] = "Billit";
    Integration["EXACT"] = "Exact";
    Integration["TEAMLEADER"] = "Teamleader";
    Integration["CODABOX"] = "Codabox";
    Integration["PONTO"] = "Ponto";
    Integration["DIGITEAL"] = "Digiteal";
    Integration["DBASICS"] = "Dbasics";
    Integration["COMPANYWEB"] = "Companyweb";
})(Integration || (Integration = {}));
// These are essentially integrations that the client cannot perform on his own.
// They do not exist in the UI but can sent from the backend and need to be tested against in some cases.
export var Connector;
(function (Connector) {
    // Inconsistent casing unfortunately deliberate.
    Connector["BOB"] = "bob";
    Connector["WINBOOKS"] = "Winbooks";
})(Connector || (Connector = {}));
export var ENABLED_INTEGRATIONS = [
    Integration.YUKI,
    Integration.HORUS,
    // Integration.BILLIT,
    Integration.EXACT,
    Integration.TEAMLEADER,
    Integration.CODABOX,
    Integration.PONTO,
    Integration.DBASICS,
    Integration.DIGITEAL,
    Integration.COMPANYWEB,
];
export var IntegrationQueryParams = {
    NAME: 'integration',
    CATEGORY: 'category',
};
export var INTEGRATIONS_ROUTE = '/settings/integrations';
export var INTEGRATION_ROUTE = (_a = {},
    _a[Integration.YUKI] = "/settings/integration/".concat(Integration.YUKI.toLowerCase()),
    _a[Integration.HORUS] = "/settings/integration/".concat(Integration.HORUS.toLowerCase()),
    _a[Integration.BILLIT] = "/settings/integration/".concat(Integration.BILLIT.toLowerCase()),
    _a[Integration.EXACT] = "/settings/integration/".concat(Integration.EXACT.toLowerCase()),
    _a[Integration.TEAMLEADER] = "/settings/integration/".concat(Integration.TEAMLEADER.toLowerCase()),
    _a[Integration.CODABOX] = "/settings/integration/".concat(Integration.CODABOX.toLowerCase()),
    _a[Integration.PONTO] = "/settings/integration/".concat(Integration.PONTO.toLowerCase()),
    _a[Integration.DBASICS] = "/settings/integration/".concat(Integration.DBASICS.toLowerCase()),
    _a[Integration.DIGITEAL] = "/settings/integration/".concat(Integration.DIGITEAL.toLowerCase()),
    _a[Integration.COMPANYWEB] = "/settings/integration/".concat(Integration.COMPANYWEB.toLowerCase()),
    _a);
export var IntegrationCategory;
(function (IntegrationCategory) {
    IntegrationCategory["ALL"] = "ALL";
    IntegrationCategory["ACCOUNTING"] = "ACCOUNTING";
    IntegrationCategory["ERP"] = "ERP";
    IntegrationCategory["BANKING"] = "BANKING";
    IntegrationCategory["MY_INTEGRATIONS"] = "MY_INTEGRATIONS";
    IntegrationCategory["MONITORING"] = "MONITORING";
})(IntegrationCategory || (IntegrationCategory = {}));
