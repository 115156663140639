import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import { IconName } from 'shared/components/Icon';
import { Button, UploadZone } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { accountActions } from 'store/account/account.actions';
import { formActions } from 'store/form/form.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, progressReset, showDialog, sideMenuHide, } from 'store/view/view.actions';
import ImportCsvForm from '../ImportCsvForm';
import styleIdentifiers from './invoiceImportForm.scss';
var styles = classNames.bind(styleIdentifiers);
export default function InvoiceImportForm(_a) {
    var onlyCSV = _a.onlyCSV;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var ublFiles = useSelector(function (state) { return state.form.ublFiles; });
    var progress = useSelector(function (state) { return state.view.progress; });
    var history = useHistory();
    var location = useLocation();
    var uploadChange = function (files) {
        formActions.changeValue({ key: 'ublFiles', value: files });
        progressReset();
    };
    var onUploadPdf = function (files) {
        formActions.changeValue({ key: 'pdfFiles', value: files });
        if (location.pathname !== '/invoices/import-pdf')
            history.push('/invoices/import-pdf');
        sideMenuHide();
    };
    var onUploadCsv = function (file) {
        showDialog({
            id: DialogShowId.CUSTOM,
            title: t(i18nKeys.IMPORT_CSV),
            children: React.createElement(ImportCsvForm, { update: onlyCSV, file: file }),
        });
        sideMenuHide();
    };
    var importUbl = function (planId) {
        accountActions.importInvoice({
            ublFiles: ublFiles,
            planId: planId,
            callback: function () {
                formActions.changeValue({ key: 'ublFiles', value: null });
            },
        });
    };
    var chooseRecoveryPlan = function (e) {
        e.stopPropagation();
        if (company.active_plans_count > 1) {
            settingsActions.recoveryPlanList({
                callback: function (res) {
                    var plans = res.data.map(function (plan) { return treatRecoveryPlan(plan); });
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.PLAN.SELECT_PLAN),
                        children: (React.createElement(ChooseRecoveryPlanModal, { recoverPlans: plans, onClick: function (planId) {
                                importUbl(planId);
                                dialogHide(DialogShowId.CUSTOM);
                            } })),
                    });
                },
            });
        }
        else {
            importUbl();
        }
    };
    return (React.createElement("form", { className: styles('InvoiceImportForm') },
        React.createElement("div", { className: styles('head') }, t(i18nKeys.INVOICE_IMPORT)),
        React.createElement("div", { className: styles('content') },
            !onlyCSV && (React.createElement(UploadZone, { noMargin: true, icon: IconName.XML, accept: ".xml", info: t(i18nKeys.INVOICING.IMPORT.UBL_INVOICES), noPreview: true, onChange: uploadChange, multiple: true, progress: progress, action: chooseRecoveryPlan, value: ublFiles })),
            !onlyCSV && (React.createElement(UploadZone, { icon: IconName.PDF, accept: ".pdf", info: t(i18nKeys.INVOICING.IMPORT.PDF_INVOICES), noPreview: true, onChange: onUploadPdf, multiple: true })),
            React.createElement("div", { className: styles('csv-container') },
                React.createElement(UploadZone, { icon: IconName.CSV, accept: ".csv", info: t(i18nKeys.INVOICING.IMPORT.CSV_INVOICES), noPreview: true, onChange: onUploadCsv }),
                React.createElement("div", { className: styles('mobile-element') }, t(i18nKeys.UNAVAILABLE_WITH_SCREEN_SIZE)))),
        !onlyCSV && company.unvalidated_invoices_count > 0 && (React.createElement("div", { className: styles('button-wrapper') },
            React.createElement(Button, { noMargin: true, label: "".concat(t(i18nKeys.INVOICING.IMPORT.SEE_INVOICES_TO_CONFIRM), " (").concat(company.unvalidated_invoices_count, ")"), onClick: function () {
                    history.push('/invoices/to-confirm');
                    sideMenuHide();
                } })))));
}
