import { __assign, __read, __spreadArray } from "tslib";
import React, { useState } from 'react';
import { useCreateReason, useGetReasons } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { DateTime } from 'luxon';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, CustomSelect, DateSelector } from 'shared/io';
import { useGetClientState, useGetInvoiceState } from 'shared/utils/selectors';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { ReasonForm } from '../ReasonForm/ReasonForm';
import styleIdentifiers from './SetReasonForm.scss';
var styles = classNames.bind(styleIdentifiers);
export var SetReasonForm = function (_a) {
    var onSubmit = _a.onSubmit, reasonType = _a.reasonType;
    var t = useTranslation().t;
    var isInvoiceLoading = useGetInvoiceState().isLoading;
    var isClientLoading = useGetClientState().isLoading;
    var isLoading = isInvoiceLoading || isClientLoading;
    var reasonsListResponse = useGetReasons({
        reason_type: reasonType,
    }).data;
    var form = useForm({
        shouldUnregister: true,
    });
    var handleSubmit = form.handleSubmit, setValue = form.setValue;
    var minDueDate = DateTime.now();
    var reasons = (reasonsListResponse === null || reasonsListResponse === void 0 ? void 0 : reasonsListResponse.data.map(function (e) { return ({
        description: e.attributes.name,
        value: e.id,
    }); })) || [];
    var _b = __read(useState(undefined), 2), newReasonsList = _b[0], setNewReasonsList = _b[1];
    var createReason = useCreateReason().mutate;
    var onSubmitReason = function (data) {
        return createReason({ data: data }, {
            onSuccess: function (_a) {
                var newReason = _a.data;
                setNewReasonsList(__spreadArray(__spreadArray([], __read(reasons), false), [
                    {
                        description: newReason.attributes.name,
                        value: newReason.id,
                    },
                ], false));
                setValue('reason_id', newReason.id.toString());
                dialogHide(DialogShowId.CONFIRM);
            },
        });
    };
    var addReason = function () {
        return showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.ADD_REASON),
            children: (React.createElement(ReasonForm, { onSubmit: onSubmitReason, selectedType: reasonType, onCancel: function () { return dialogHide(DialogShowId.CONFIRM); } })),
        });
    };
    return (React.createElement(FormProvider, __assign({}, form),
        React.createElement("form", { className: styles('reason-form'), onSubmit: handleSubmit(onSubmit) },
            React.createElement("div", { className: styles('field-type') },
                React.createElement(Controller, { rules: { required: true }, name: "reason_id", render: function () { return (React.createElement(CustomSelect, { selectClassName: styles('input'), keyText: "description", keyValue: "value", withBorder: true, name: "reason_id", label: t(i18nKeys.FORM.REASON), items: newReasonsList || reasons, filter: true })); } }),
                React.createElement(Button, { label: "+", title: t(i18nKeys.ADD_TASK_TYPE), onClick: addReason, noMargin: true })),
            reasonType === 'pause' && (React.createElement("div", { className: styles('content') },
                React.createElement(Controller, { name: "end_at", render: function () { return (React.createElement(DateSelector, { label: t(i18nKeys.FORM.END_DATE), name: "end_at", withBorder: true, className: styles('input', 'date-selector'), inputClassName: styles('value'), errorClassName: styles('error'), minDate: minDueDate.toISODate(), placeholder: minDueDate.toLocaleString(), handleChange: function (value) {
                            setValue('end_at', value);
                        } })); } }))),
            React.createElement("div", { className: styles('button-wrapper', 'buttons') },
                React.createElement(Button, { type: "submit", isLoading: isLoading, label: t(i18nKeys.CONFIRM) })))));
};
