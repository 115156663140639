import { __read } from "tslib";
import React, { useState } from 'react';
import { Group, SegmentedControl } from '@mantine/core';
import { styled } from '@stitches/react';
import { ActionableTicker } from './tickers/ActionableTicker';
import { AtRiskTicker } from './tickers/AtRiskTicker';
import { DisputedTicker } from './tickers/DisputedTicker';
import { PausedTicker } from './tickers/PausedTicker';
import { TotalReceivableTicker } from './tickers/TotalReceivableTicker';
var SegmentedControlBackgroundOverride = styled('span', {
    '.mantine-SegmentedControl-root': {
        backgroundColor: 'var(--mantine-color-gray-2)',
    },
});
export var TickersBanner = function () {
    var _a = __read(useState('percentage'), 2), format = _a[0], setFormat = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Group, { mb: "lg", mt: "md", justify: "space-between" },
            React.createElement(TotalReceivableTicker, null),
            React.createElement(AtRiskTicker, { format: format }),
            React.createElement(DisputedTicker, { format: format }),
            React.createElement(PausedTicker, { format: format }),
            React.createElement(ActionableTicker, { format: format }),
            React.createElement(SegmentedControlBackgroundOverride, null,
                React.createElement(SegmentedControl, { value: format, onChange: function (value) { return setFormat(value); }, size: "sm", orientation: "vertical", data: [
                        { label: '%', value: 'percentage' },
                        { label: '123', value: 'absolute' },
                    ] })))));
};
