import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import Amount from 'shared/components/Amount';
import Loading from 'shared/components/Loading';
import MultiCurrencyData from 'shared/components/MultiCurrencyData';
import { accountActions } from 'store/account/account.actions';
import styleIdentifiers from './DetailedBalance.scss';
var styles = classNames.bind(styleIdentifiers);
export function DetailedBalance(_a) {
    var accountManagerId = _a.accountManagerId;
    var t = useTranslation().t;
    var data = useSelector(function (state) { var _a; return (_a = state.account.dashboard.data) === null || _a === void 0 ? void 0 : _a.detailed_balance; });
    useEffect(function () {
        accountActions.dashboardBalance({
            noLoading: true,
            data: {
                account_manager_id: accountManagerId,
            },
        });
    }, [accountManagerId]);
    return data ? (React.createElement("div", { className: styles('balance-detailed') },
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.AMOUNT_BILLED)),
            React.createElement("div", { className: styles('value') },
                React.createElement(MultiCurrencyData, { data: data.invoices_total, withTotal: true }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.CLAIMED_LATE_FEES)),
            React.createElement("div", { className: styles('value') },
                React.createElement(MultiCurrencyData, { data: data.claimed_fees, withTotal: true }))),
        React.createElement("div", { className: styles('item', 'right') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.NAV.CREDIT_NOTES)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(MultiCurrencyData, { data: data.credit_notes_total, withTotal: true }))),
        React.createElement("div", { className: styles('item', 'right') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.PAYMENTS)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(Amount, { value: -data.payments_total }))),
        React.createElement("div", { className: styles('item', 'right') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.LOST_SUM)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(Amount, { value: -data.lost_total }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') },
                t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE),
                " ",
                React.createElement("span", null,
                    "(",
                    t(i18nKeys.WITH_FEES),
                    ")")),
            React.createElement("div", { className: styles('value', 'main-color') },
                React.createElement(Amount, { value: data.remaining_balance_with_fees }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') }, t(i18nKeys.REMAINING_LATE_FEES)),
            React.createElement("div", { className: styles('value', 'color-grey') },
                React.createElement(Amount, { value: -data.remaining_late_fees }))),
        React.createElement("div", { className: styles('item') },
            React.createElement("div", { className: styles('label') },
                t(i18nKeys.CLIENT.DETAIL.REMAIN_BALANCE),
                " ",
                React.createElement("span", null,
                    "(",
                    t(i18nKeys.WITHOUT_FEES),
                    ")")),
            React.createElement("div", { className: styles('value', 'main-color') },
                React.createElement(Amount, { value: data.remaining_balance }))))) : (React.createElement("div", { className: styles('balance-detailed') },
        React.createElement(Loading, { active: true }),
        t(i18nKeys.TOO_LONG_LOADING)));
}
