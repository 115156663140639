import apiService from 'shared/service/api.service';
import { config } from 'store/constants';
import { ENABLED_INTEGRATIONS, Integration } from '../Integrations.constants';
var baseUrl = apiService.baseUrl, tenant = apiService.tenant;
var exactClientId = config.exact.client_id, _a = config.billit, billitClientId = _a.client_id, billitBaseUrl = _a.base_url;
var publicUrl = baseUrl.replace('backend', 'public.backend');
export var isEnabledIntegration = function (integration) {
    return ENABLED_INTEGRATIONS.includes(integration);
};
export var getIntegrationRights = function (integration, company) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    switch (integration) {
        case Integration.CODABOX:
            return {
                canActivate: isEnabledIntegration(integration) && ((_a = company === null || company === void 0 ? void 0 : company.codabox_integration) === null || _a === void 0 ? void 0 : _a.can_activate),
                isActive: (_b = company === null || company === void 0 ? void 0 : company.codabox_integration) === null || _b === void 0 ? void 0 : _b.active,
            };
        case Integration.PONTO:
            return {
                canActivate: isEnabledIntegration(integration) && ((_c = company === null || company === void 0 ? void 0 : company.ponto_integration) === null || _c === void 0 ? void 0 : _c.can_activate),
                isActive: (_d = company === null || company === void 0 ? void 0 : company.ponto_integration) === null || _d === void 0 ? void 0 : _d.active,
            };
        case Integration.DIGITEAL:
            return {
                canActivate: isEnabledIntegration(integration) && ((_e = company === null || company === void 0 ? void 0 : company.package) === null || _e === void 0 ? void 0 : _e.can_use_online_payment),
                isActive: (_f = company === null || company === void 0 ? void 0 : company.digiteal_integration) === null || _f === void 0 ? void 0 : _f.use_integration,
            };
        case Integration.COMPANYWEB:
            return {
                canActivate: true,
                isActive: company.company_web_credentials.login != null ||
                    company.company_web_credentials.password != null,
            };
        default:
            return {
                canActivate: isEnabledIntegration(integration) &&
                    (((_h = (_g = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _g === void 0 ? void 0 : _g.active) === null || _h === void 0 ? void 0 : _h.includes(integration)) ||
                        ((_k = (_j = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _j === void 0 ? void 0 : _j.active) === null || _k === void 0 ? void 0 : _k.length) === 0),
                // Have we logged into the service?
                isConnected: (_o = (_m = (_l = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _l === void 0 ? void 0 : _l.integration_in_use) === null || _m === void 0 ? void 0 : _m.includes(integration)) !== null && _o !== void 0 ? _o : false,
                // Have minimum required config steps been taken, if any? (Most commonly choosing a company)
                isConfigure: (_r = (_q = (_p = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _p === void 0 ? void 0 : _p.can_configure) === null || _q === void 0 ? void 0 : _q.includes(integration)) !== null && _r !== void 0 ? _r : false,
                // Have we checked the checkboxes that actually cause the imports to happen?
                isActive: (_u = (_t = (_s = company === null || company === void 0 ? void 0 : company.tp_accounting) === null || _s === void 0 ? void 0 : _s.active) === null || _t === void 0 ? void 0 : _t.includes(integration)) !== null && _u !== void 0 ? _u : false,
            };
    }
};
export var getTeamleaderConnectUrl = function () {
    return "https://app.teamleader.eu/oauth2/authorize?client_id=53efcfb7aaa8e786191ba73fbfcab285&response_type=code&state=".concat(tenant, "&redirect_uri=").concat(publicUrl, "/private_api/teamleader_callbacks/authenticate");
};
export var getHorusConnectUrl = function (currentLang) {
    return "https://my-horus.com/".concat(currentLang === 'nl' ? currentLang : 'fr', "/api/oauth2/authorize?client_id=ck_sEzBr3cObz5fAJo&response_type=code&state=").concat(tenant, "&redirect_uri=").concat(publicUrl, "/api/horus_callbacks/authenticate");
};
export var getExactConnectUrl = function () {
    return "https://start.exactonline.be/api/oauth2/auth?client_id=".concat(exactClientId, "&redirect_uri=").concat(publicUrl, "/private_api/exact_callbacks/authenticate?tenant=").concat(tenant, "&response_type=code&force_login=0");
};
export var getBillitConnectUrl = function (currentLang) {
    return "".concat(billitBaseUrl, "Account/Logon?client_id=").concat(billitClientId, "&state=").concat(tenant, "&redirect_uri=").concat(publicUrl, "/private_api/billit_callbacks/authenticate");
};
export var isMaskFilled = function (value) {
    return (value === null || value === void 0 ? void 0 : value.indexOf('_')) !== -1 ? 'ERROR.FIELD_REQUIRED' : true;
};
