import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { Center, Stack, Text, Title } from '@mantine/core';
export var CreditLimit = function (_a) {
    var creditLimit = _a.creditLimit;
    var t = useTranslation().t;
    var status = creditLimit.status, limit = creditLimit.limit;
    return (React.createElement(Center, { h: "100%" },
        React.createElement(Stack, { justify: "space-between" },
            React.createElement(Title, { order: 3, mb: "lg" }, t(i18nKeys.DEBTOR.MONITORING.CREDIT_LIMIT)),
            React.createElement(Center, { w: "100%" },
                React.createElement(Stack, { align: "center" },
                    React.createElement(Text, { size: "50", fw: 500, lh: 1, c: limit === 0 ? 'red' : 'black' },
                        limit,
                        " \u20AC"),
                    React.createElement(Text, { size: "lg", c: limit === 0 ? 'red' : 'black' }, status))))));
};
