import { __makeTemplateObject, __read, __spreadArray } from "tslib";
import React from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { css } from '@emotion/css';
import { Box, Checkbox, Divider, Group, Paper, Radio, Text, UnstyledButton } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
var styles = {
    calendar: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: fit-content;\n    [data-weekend] {\n      color: black;\n    }\n\n    .mantine-DatePicker-calendarHeader {\n      display: none;\n    }\n\n    .mantine-DatePicker-day {\n      border-radius: var(--mantine-radius-sm);\n      :hover {\n        background-color: var(--mantine-color-gray-1);\n      }\n    }\n\n    [data-selected] {\n      color: white;\n\n      :hover {\n        background-color: var(--mantine-color-blue-6);\n      }\n    }\n  "], ["\n    width: fit-content;\n    [data-weekend] {\n      color: black;\n    }\n\n    .mantine-DatePicker-calendarHeader {\n      display: none;\n    }\n\n    .mantine-DatePicker-day {\n      border-radius: var(--mantine-radius-sm);\n      :hover {\n        background-color: var(--mantine-color-gray-1);\n      }\n    }\n\n    [data-selected] {\n      color: white;\n\n      :hover {\n        background-color: var(--mantine-color-blue-6);\n      }\n    }\n  "]))),
    dayOfWeekCheckbox: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: start;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    background-color: var(--mantine-color-gray-0);\n    cursor: pointer;\n    padding: var(--mantine-spacing-md);\n    margin-top: var(--mantine-spacing-xs);\n    margin-bottom: var(--mantine-spacing-xs);\n    width: 100%;\n  "], ["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: start;\n    text-align: center;\n    border-radius: var(--mantine-radius-md);\n    background-color: var(--mantine-color-gray-0);\n    cursor: pointer;\n    padding: var(--mantine-spacing-md);\n    margin-top: var(--mantine-spacing-xs);\n    margin-bottom: var(--mantine-spacing-xs);\n    width: 100%;\n  "]))),
};
export var PeriodicityStep = function (_a) {
    var form = _a.form;
    var t = useTranslation().t;
    var handleToggleDay = function (day) {
        if (form.values.daysWeek.includes(day)) {
            form.setFieldValue('daysWeek', form.values.daysWeek.filter(function (d) { return d !== day; }));
        }
        else {
            form.setFieldValue('daysWeek', __spreadArray(__spreadArray([], __read(form.values.daysWeek), false), [day], false));
        }
    };
    var daysInWeek = Object.values(i18nKeys.DATES.DAY_NAMES).map(function (value) { return t(value); });
    return (React.createElement(Group, { align: "start" },
        React.createElement(Box, { onClick: form.values.periodicity === 'monthly'
                ? function () {
                    form.setFieldValue('periodicity', 'weekly');
                    form.setFieldValue('daysMonth', []);
                }
                : function () { } },
            React.createElement(Radio, { readOnly: true, styles: { body: { alignItems: 'center' } }, checked: form.values.periodicity === 'weekly', label: React.createElement(Text, { fw: 500, size: "lg" }, t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.WEEKLY_TITLE)) }),
            React.createElement(Box, { ml: 34, style: {
                    opacity: form.values.periodicity === 'monthly' ? 0.25 : 1,
                    pointerEvents: form.values.periodicity === 'monthly' ? 'none' : 'auto',
                } }, daysInWeek.map(function (day, index) { return (React.createElement(UnstyledButton, { key: day, onClick: function () { return handleToggleDay(index + 1); }, className: styles.dayOfWeekCheckbox },
                React.createElement(Checkbox, { readOnly: true, mr: "md", checked: form.values.daysWeek.includes(index + 1) }),
                React.createElement(Text, null, day))); }))),
        React.createElement(Divider, { orientation: "vertical", mx: "lg" }),
        React.createElement(Box, { onClick: form.values.periodicity === 'weekly'
                ? function () {
                    form.setFieldValue('periodicity', 'monthly');
                    form.setFieldValue('daysWeek', []);
                }
                : function () { } },
            React.createElement(Radio, { readOnly: true, onClick: function () { return form.setFieldValue('periodicity', 'monthly'); }, styles: { body: { alignItems: 'center' } }, checked: form.values.periodicity === 'monthly', label: React.createElement(Text, { fw: 500, size: "lg" }, t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.MONTHLY_TITLE)) }),
            React.createElement("div", { style: { opacity: form.values.periodicity === 'weekly' ? 0.25 : 1 } },
                React.createElement(Paper, { p: "md", ml: 34, mt: "sm", bg: "gray.0", shadow: "none", w: "fit-content" },
                    React.createElement(DatePicker, { style: {
                            pointerEvents: form.values.periodicity === 'weekly' ? 'none' : 'auto',
                        }, value: form.values.daysMonth, className: styles.calendar, styles: {
                            calendarHeader: { display: 'none' },
                        }, 
                        // todo: Extract the reference date
                        defaultDate: new Date('01-01-2024'), hideOutsideDates: true, hideWeekdays: true, type: "multiple", onChange: function (val) { return form.setFieldValue('daysMonth', val); } })),
                React.createElement(Text, { w: 300, ml: 34, mt: "xs", c: "dimmed", size: "xs" }, t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.MONTHLY_END_OF_MONTH_DISCLAIMER)),
                React.createElement(Text, { w: 300, ml: 34, mt: "xs", c: "dimmed", size: "xs" }, t(i18nKeys.REPORTS.NEW.STEPS.RECURRENCE.LAST_DAY_DISCLAIMER))))));
};
var templateObject_1, templateObject_2;
