import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import BankAccount from 'shared/components/BankAccount';
import Card from 'shared/components/Card';
import { CustomSelect } from 'shared/io';
import BankStatusAmount from './BankStatusAmount';
import styleIdentifiers from './bankStatus.scss';
var styles = classNames.bind(styleIdentifiers);
export default function BankStatus(_a) {
    var _b, _c;
    var data = _a.data;
    var t = useTranslation().t;
    var company = useSelector(function (state) { return state.account.company.data; });
    var form = useForm({ shouldUnregister: true });
    var saldo = data.saldo, saldo_one_week = data.saldo_one_week, saldo_one_month = data.saldo_one_month;
    var balance = (_b = saldo[form.getValues().bank_accounts]) !== null && _b !== void 0 ? _b : saldo.total;
    var currency = company.currency;
    return (React.createElement(Card, { title: t(i18nKeys.DASHBOARD.BANK_STATUS.TITLE), className: styles('BankStatus') },
        React.createElement("div", { className: styles('status') },
            company.bank_accounts_attributes && (React.createElement("div", { className: styles('item') },
                ((_c = company.bank_accounts_attributes) === null || _c === void 0 ? void 0 : _c.length) > 1 ? (React.createElement(FormProvider, __assign({}, form),
                    React.createElement(Controller, { name: "bank_accounts", defaultValue: "", render: function () { return (React.createElement(CustomSelect, { name: "bank_accounts", removeAll: t(i18nKeys.FORM.SELECT_ALL), valueClassName: styles('select-value'), items: company.bank_accounts_attributes, valueRendering: function (value) { return (React.createElement("span", { className: styles('label') }, value.value
                                ? t(i18nKeys.DASHBOARD.BANK_STATUS.ACCOUNT_BALANCE, {
                                    text: value.text,
                                })
                                : t(i18nKeys.DASHBOARD.BANK_STATUS.ALL_ACCOUNTS_BALANCE))); }, itemRendering: function (_a) {
                                var item = _a.item;
                                return (React.createElement(BankAccount, { value: item.iban, image: item.bic, small: true }));
                            }, keyText: "iban", keyValue: "id", noBorder: true, noMargin: true })); } }))) : (React.createElement("div", { className: styles('label') }, t(i18nKeys.BALANCE))),
                React.createElement("div", { className: styles('value') },
                    React.createElement(BankStatusAmount, { data: balance, currency: currency })))),
            React.createElement("div", { className: styles('item') },
                React.createElement("div", { className: styles('label') }, t(i18nKeys.DASHBOARD.BANK_STATUS.WEEK_FORECAST)),
                React.createElement("div", { className: styles('value', 'color-grey') },
                    React.createElement(BankStatusAmount, { data: saldo_one_week, currency: currency }))),
            React.createElement("div", { className: styles('item') },
                React.createElement("div", { className: styles('label') }, t(i18nKeys.DASHBOARD.BANK_STATUS.MONTH_FORECAST)),
                React.createElement("div", { className: styles('value', 'main-color') },
                    React.createElement(BankStatusAmount, { data: saldo_one_month, currency: currency }))))));
}
