import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import ThirdpartyCaseListItem from 'shared/components/ThirdpartyCaseListItem';
import ThirdpartyCasesFiltersForm from 'shared/forms/ThirdpartyCasesFiltersForm';
import { thirdPartyCaseActions } from 'store/thirdpartyCase/thirdpartycase.actions';
import styleIdentifiers from './ThirdpartyCaseListing.scss';
var styles = classNames.bind(styleIdentifiers);
var EnhancedTable = CustomTable(ThirdpartyCaseListItem, ThirdpartyCasesFiltersForm);
export default function ThirdpartyCaseList() {
    var t = useTranslation().t;
    var thirdpartyCaseData = useSelector(function (state) { return state.thirdpartyCase.list; });
    var THIRD_PARTY_CASES_TABLE_HEADERS = [
        {
            key: 'case_type',
            title: t(i18nKeys.CASE_TYPE),
            width: 140,
        },
        {
            key: 'case_reference',
            title: t(i18nKeys.FORM.REFERENCE),
            width: 160,
        },
        {
            key: 'created_at',
            title: t(i18nKeys.CREATION_DATE),
            width: 140,
        },
        {
            key: 'updated_at',
            title: t(i18nKeys.LAST_UPDATE),
            width: 140,
        },
        {
            key: 'debtor_name',
            title: t(i18nKeys.COMMON.CLIENT),
            width: 160,
        },
        {
            key: 'status',
            title: t(i18nKeys.STATUS),
            width: 120,
        },
        {
            key: 'total_tvac',
            title: t(i18nKeys.TOTAL_VAT_PRICE),
        },
        {
            key: 'remaining_balance',
            title: t(i18nKeys.BALANCE),
        },
    ];
    var handleFilters = function (filters) {
        var newFilters = __assign({}, filters);
        // need to be linked
        if ((newFilters.total_tvac_operator && !newFilters.total_tvac) ||
            (!newFilters.total_tvac_operator && newFilters.total_tvac))
            return false;
        // need to be linked
        if ((newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
            (!newFilters.remaining_balance_operator && newFilters.remaining_balance))
            return false;
        return newFilters;
    };
    var orderField = function (field) { return (field.indexOf('debtor') >= 0 ? 'debtor_name' : field); };
    var customKey = function (tp_case) { return tp_case.case_type + tp_case.id; };
    return (React.createElement("div", { className: styles('Listing') },
        React.createElement(EnhancedTable, { title: t(i18nKeys.THIRD_PARTY_CASES.THIRD_PARTY_CASES_LIST), showShadow: true, orderField: orderField, loadFunc: thirdPartyCaseActions.listPage, loading: thirdpartyCaseData.loading, loaded: thirdpartyCaseData.loaded, pagination: thirdpartyCaseData.metadata && thirdpartyCaseData.metadata.pagination, headers: THIRD_PARTY_CASES_TABLE_HEADERS, handleFilters: handleFilters, items: thirdpartyCaseData.pages, customKey: customKey, noCheckbox: true })));
}
