import React from 'react';
import ToHandleDetail from 'app/Private/Invoices/ToHandleDetail';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { useHistory } from 'react-router';
import ActivityDetail from 'shared/components/ActivityDetail';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { dateFromNow } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import styleIdentifiers from './activitiesItem.scss';
var styles = classNames.bind(styleIdentifiers);
export default function ActivitiesItem(_a) {
    var item = _a.item, noCheckbox = _a.noCheckbox;
    var _b = useTranslation(), t = _b.t, currentLang = _b.currentLang;
    var history = useHistory();
    var isCreditNote = item.tracked_type === 'CreditNote';
    var openModal = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            children: React.createElement(ActivityDetail, { item: item }),
        });
    };
    var detailModal = function (e) {
        e.stopPropagation();
        showDialog({
            id: DialogShowId.INVOICE,
            size: DialogShowSize.LARGE,
            children: React.createElement(ToHandleDetail, { isCreditNote: isCreditNote, id: item.tracked_id, modal: true }),
        });
    };
    var detailDebtor = function (e) {
        e.stopPropagation();
        history.push("/clients/".concat(item.tracked_id));
    };
    var detailPaymentPlan = function (e) {
        e.stopPropagation();
        history.push("/payment-plan/".concat(item.payment_plan_id));
    };
    var detailTask = function (e) {
        e.stopPropagation();
        history.push("/tasks/".concat(item.tracked_id));
    };
    var showPaymentPlan = item.payment_plan_id;
    var showClient = item.tracked_type === 'Debtor' && !showPaymentPlan;
    var date = dateFromNow(item.date, currentLang);
    return (React.createElement("tr", { className: styles('ActivitiesItem'), key: item.id, onClick: openModal },
        React.createElement("td", { className: styles('checkbox', noCheckbox && 'small') }, item.new && (React.createElement("div", { className: styles('archive') },
            React.createElement("div", { className: styles('new', 'circle') }),
            React.createElement("span", { className: styles('txt') }, "NEW")))),
        React.createElement("td", { onClick: openModal }, item.author),
        React.createElement("td", { onClick: openModal }, date),
        React.createElement("td", { onClick: openModal, className: styles('description') },
            React.createElement(HTML, { html: item.description })),
        React.createElement("td", { className: styles('actions') },
            (item.tracked_type === 'Invoice' || isCreditNote) && (React.createElement("div", { onClick: detailModal },
                t(isCreditNote ? i18nKeys.ACTIVITIES.SEE_CREDIT_NOTE : i18nKeys.ACTIVITIES.SEE_INVOICE),
                React.createElement("div", { className: styles('icon-wrapper') },
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, className: styles('dark'), size: "13px" })))),
            showClient && (React.createElement("div", { onClick: detailDebtor },
                t(i18nKeys.ACTIVITIES.SEE_CLIENT),
                React.createElement("div", { className: styles('icon-wrapper') },
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, className: styles('dark'), size: "13px" })))),
            showPaymentPlan && (React.createElement("div", { onClick: detailPaymentPlan },
                t(i18nKeys.ACTIVITIES.SEE_PAYMENT_PLAN),
                React.createElement("div", { className: styles('icon-wrapper') },
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, className: styles('dark'), size: "13px" })))),
            item.tracked_type === 'Task' && (React.createElement("div", { onClick: detailTask },
                t(i18nKeys.ACTIVITIES.SEE_TASK),
                React.createElement("div", { className: styles('icon-wrapper') },
                    React.createElement(Icon, { name: IconName.CIRCLE_RIGHT, className: styles('dark'), size: "13px" })))))));
}
