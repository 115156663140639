import { __assign, __awaiter, __generator, __read, __rest } from "tslib";
import React, { useState } from 'react';
import CustomVariablesBatchForm from 'app/Private/CustomVariables/CustomVariablesBatchForm/CustomVariablesBatchForm';
import { SetReasonForm } from 'app/Private/Reasons/SetReasonForm/SetReasonForm';
import { TaskForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales/';
import { isNil, omit, reject } from 'lodash-es';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import ListResModal from 'shared/components/DeleteModal';
import { Icon, IconName } from 'shared/components/Icon';
import { ChooseThirdPartyTypeForm } from 'shared/components/InvoiceActionsDropdown/ChooseThirdPartyTypeForm';
import FormalNoticeForm from 'shared/components/InvoiceActionsDropdown/FormalNotice/FormalNoticeForm';
import { GcollectModal } from 'shared/components/InvoiceActionsDropdown/GcollectModal';
import SelectThirdPartyForm from 'shared/components/InvoiceActionsDropdown/SelectThirdPartyForm';
import MoveRecoveryPlanInvoiceModal from 'shared/components/MoveRecoveryPlanInvoiceModal';
import { useIsConnectorActive } from 'shared/hooks';
import { ButtonColor } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { useRefresh } from 'shared/utils/hooks';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, error, showDialog, sideMenuHide, } from 'store/view/view.actions';
import { modals } from '@mantine/modals';
import SendingOptionsForm from '../ToHandleDetail/SendingOptionsForm';
import styleIdentifiers from './BatchActions.scss';
var styles = classNames.bind(styleIdentifiers);
export var Categories;
(function (Categories) {
    Categories["RECOVERY"] = "RECOVERY";
    Categories["STATUS"] = "STATUS";
    Categories["FOLLOW_UP"] = "FOLLOW_UP";
    Categories["ASSIGNMENT"] = "ASSIGNMENT";
    Categories["OTHER"] = "OTHER";
})(Categories || (Categories = {}));
export default function BatchActions(_a) {
    var _this = this;
    var selectedInvoices = _a.selectedInvoices, _isSelectedAll = _a.isSelectedAll, reload = _a.reload;
    var t = useTranslation().t;
    var _b = __read(useState(_isSelectedAll), 2), isSelectedAll = _b[0], setSelectedAll = _b[1];
    var _c = __read(useState(), 2), setCurrentTemplateId = _c[1];
    var company = useSelector(function (state) { return state.account.company.data; });
    var invoicesData = useSelector(function (state) { return state.invoice.list; });
    var doRefresh = useRefresh().doRefresh;
    var history = useHistory();
    var isConnectorActive = useIsConnectorActive();
    if (company == null)
        return null;
    var aysModal = function (action_type) { return function (onSubmit) {
        showDialog({
            id: DialogShowId.CONFIRM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CONFIRMATION),
            children: (React.createElement(AYSModal, { text: t(selectedInvoices.length === 1 && !isSelectedAll
                    ? i18nKeys.AYS["INVOICE_ACTION_".concat(action_type.toUpperCase())]
                    : i18nKeys.AYS["INVOICES_ACTION_".concat(action_type.toUpperCase())], {
                    count: isSelectedAll
                        ? invoicesData.metadata.pagination.total_objects
                        : selectedInvoices.length,
                }), onConfirm: function () { return onSubmit({}); } })),
        });
    }; };
    var batchAction = function (action_type, action) { return function () {
        sideMenuHide();
        var data = {};
        if (isSelectedAll) {
            data.filter_params = queryParamParser(qs.parse(location.search));
            data.view_id = location.hash.slice(1);
        }
        else {
            data.invoice_ids = selectedInvoices.map(function (invoice) { return invoice.id; });
        }
        action(function (values) {
            invoiceActions.actionsBatchAction({
                data: __assign(__assign(__assign({}, data), values), { action_type: action_type }),
                callback: function (res) {
                    dialogHide(DialogShowId.CONFIRM);
                    showDialog({
                        id: DialogShowId.CUSTOM,
                        size: DialogShowSize.MEDIUM,
                        title: t(i18nKeys.RESULT),
                        children: React.createElement(ListResModal, { data: res }),
                    });
                    reloadCustomView(invoiceActions.listPageRes);
                    setSelectedAll(false);
                    doRefresh();
                    reload === null || reload === void 0 ? void 0 : reload();
                },
            });
        });
    }; };
    var paymentPlan = function () {
        var data = {};
        if (isSelectedAll) {
            data.filter_params = queryParamParser(qs.parse(location.search));
            data.view_id = location.hash.slice(1);
        }
        else {
            data.invoice_ids = selectedInvoices.map(function (invoice) { return invoice.id; });
        }
        invoiceActions.actionsBatchAction({
            data: __assign(__assign({}, data), { action_type: 'payment_plan' }),
            callback: function (response) {
                var _a = response[0], debtor_id = _a.debtor_id, invoice_ids = _a.invoice_ids;
                history.push("/payment-plans/create?debtor_id=".concat(debtor_id, "&invoice_ids=").concat(invoice_ids));
                dialogHide(DialogShowId.CUSTOM);
            },
        });
    };
    var pause = function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CLIENT.PAUSE),
            children: (React.createElement(SetReasonForm, { onSubmit: function (values) {
                    return batchAction('paused', function (submit) {
                        submit(values);
                    })();
                }, reasonType: "pause" })),
        });
    };
    var lostOrDisputed = function (actionType) { return function () {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t("INVOICE.".concat(actionType === 'lose' ? 'INVOICE_LOST' : 'DISPUTED')),
            children: (React.createElement(SetReasonForm, { onSubmit: function (values) {
                    return batchAction(actionType, function (submit) {
                        submit(values);
                    })();
                }, reasonType: actionType })),
        });
    }; };
    var selectStepPlan = function (newPlan, plans, counter, maxItems, callback, step_switchs) {
        if (step_switchs === void 0) { step_switchs = []; }
        MoveRecoveryPlanInvoices({
            newPlan: newPlan,
            oldPlan: plans[counter - 1],
            title: t(i18nKeys.PLAN.MOVE_INVOICES),
            onSubmit: function (steps_switch) {
                step_switchs.push({
                    to: newPlan.id,
                    from: plans[counter - 1].id,
                    steps_switch: steps_switch,
                });
                if (counter === maxItems) {
                    callback(step_switchs);
                }
                else {
                    selectStepPlan(newPlan, plans, counter + 1, maxItems, callback, step_switchs);
                }
            },
        });
    };
    var movePlan = function () { return __awaiter(_this, void 0, void 0, function () {
        var scope;
        var _this = this;
        return __generator(this, function (_a) {
            sideMenuHide();
            scope = {
                scope: 'invoice',
            };
            if (isSelectedAll) {
                scope = __assign({ scope: 'invoice' }, qs.parse(location.search));
                scope.view_id = location.hash.slice(1);
            }
            else {
                scope.invoice_ids = selectedInvoices.map(function (invoice) { return invoice.id; });
            }
            settingsActions.recoveryPlanList({
                callback: function (_a) { return __awaiter(_this, [_a], void 0, function (_b) {
                    var recoveryPlans, planId, response, _c, newPlan, out_of_plan, res, plans, stepIndex, plansSwitch;
                    var data = _b.data;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                recoveryPlans = data.map(function (item) { return treatRecoveryPlan(item); });
                                return [4 /*yield*/, new Promise(function (resolve) {
                                        showDialog({
                                            id: DialogShowId.CUSTOM,
                                            size: DialogShowSize.MEDIUM,
                                            title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
                                            children: React.createElement(ChooseRecoveryPlanModal, { recoverPlans: recoveryPlans, onClick: resolve }),
                                        });
                                    })];
                            case 1:
                                planId = _d.sent();
                                return [4 /*yield*/, new Promise(function (resolve) {
                                        settingsActions.getRecoveryPlan({
                                            id: planId,
                                            callback: resolve,
                                        });
                                    })];
                            case 2:
                                response = _d.sent();
                                return [4 /*yield*/, new Promise(function (resolve) {
                                        settingsActions.getPlansInvoices({
                                            data: scope,
                                            callback: function (props) {
                                                return resolve(__assign(__assign({}, props), { newPlan: treatRecoveryPlan(response.data) }));
                                            },
                                        });
                                    })];
                            case 3:
                                _c = _d.sent(), newPlan = _c.newPlan, out_of_plan = _c.out_of_plan, res = __rest(_c, ["newPlan", "out_of_plan"]);
                                plans = res.data.map(function (plan) { return treatRecoveryPlan(plan); });
                                if (!(plans.length === 0 && !out_of_plan)) return [3 /*break*/, 4];
                                error({
                                    text: i18nKeys.ERROR.NO_INVOICE_IN_PLAN,
                                });
                                return [3 /*break*/, 7];
                            case 4: return [4 /*yield*/, new Promise(function (resolve) {
                                    if (out_of_plan) {
                                        showDialog({
                                            id: DialogShowId.CUSTOM,
                                            size: DialogShowSize.LARGE,
                                            title: t(i18nKeys.PLAN.MOVE_INVOICES),
                                            children: React.createElement(MoveRecoveryPlanInvoiceModal, { onSubmit: resolve, plan: newPlan }),
                                        });
                                    }
                                    else {
                                        resolve(null);
                                    }
                                })];
                            case 5:
                                stepIndex = _d.sent();
                                return [4 /*yield*/, new Promise(function (resolve) {
                                        if (plans.length > 0) {
                                            selectStepPlan(newPlan, plans, 1, plans.length, resolve);
                                        }
                                        else {
                                            return resolve([]);
                                        }
                                    })];
                            case 6:
                                plansSwitch = _d.sent();
                                if (stepIndex !== null) {
                                    plansSwitch.push({
                                        to: planId,
                                        from: null,
                                        steps_switch: [{ from: null, to: newPlan.recovery_steps[stepIndex].id }],
                                    });
                                }
                                settingsActions.switchPlansInvoices({
                                    id: planId,
                                    data: __assign({ plans_switch: plansSwitch }, scope),
                                    callback: function (_a) {
                                        var _data = _a.data;
                                        showDialog({
                                            id: DialogShowId.CUSTOM,
                                            size: DialogShowSize.MEDIUM,
                                            title: t(i18nKeys.RESULT),
                                            children: React.createElement(ListResModal, { data: _data }),
                                        });
                                    },
                                });
                                _d.label = 7;
                            case 7: return [2 /*return*/];
                        }
                    });
                }); },
            });
            return [2 /*return*/];
        });
    }); };
    var assignToAccManager = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
            children: React.createElement(ChooseCollaboratorModal, { onSubmit: onSubmit }),
        });
    };
    var taskDetails = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.FORM.TASK.NEW),
            children: React.createElement(TaskForm, { batch: true, onSubmit: onSubmit }),
        });
    };
    var customVariablesDetails = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE),
            children: React.createElement(CustomVariablesBatchForm, { onSubmit: onSubmit, resourceType: "invoice" }),
        });
    };
    var default_templates = company.default_templates;
    var setTemplate = function (templateId) {
        setCurrentTemplateId(Number(templateId));
    };
    var additionalReminderDetails = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.INVOICE.SENDING_OPTIONS),
            children: (React.createElement(SendingOptionsForm, { customText: t(i18nKeys.INVOICE.SENDING_OPTIONS_BATCH_SUMMARY), onSubmit: onSubmit, setTemplate: setTemplate, initialValues: {
                    template_id: default_templates.additional_reminder.id,
                    send_email: false,
                    send_post: false,
                } })),
        });
    };
    var formalNoticeDetails = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            title: t(i18nKeys.INVOICE.SENDING_OPTIONS),
            children: (React.createElement(FormalNoticeForm, { batch: true, customText: t(i18nKeys.INVOICE.SENDING_OPTIONS_BATCH_SUMMARY), onSubmit: onSubmit, onChangeTemplate: setTemplate, initialValues: {
                    template_id: default_templates.formal_notice.id,
                    send_email: true,
                } })),
        });
    };
    var openGcollectFlow = function (data) {
        dialogHide(DialogShowId.CUSTOM);
        modals.open({
            title: 'Gcollect',
            centered: true,
            children: (React.createElement(GcollectModal, { gcollectId: data.third_party_company_id, invoiceIds: selectedInvoices.map(function (_a) {
                    var id = _a.id;
                    return id;
                }) })),
        });
    };
    var openThirdParty = function (thirdParties, selectedThirdPartyType, onSubmitFunction) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.TITLE),
            children: (React.createElement(SelectThirdPartyForm, { onSubmit: onSubmitFunction, onSubmitGcollect: function (data) { return openGcollectFlow(data); }, thirdParties: thirdParties, selectedThirdPartyType: selectedThirdPartyType })),
        });
    };
    var thirdPartyDetails = function (onSubmit) {
        showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.LARGE,
            title: t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.TITLE),
            children: (React.createElement(ChooseThirdPartyTypeForm, { invoices: selectedInvoices.map(function (invoice) { return (__assign(__assign({}, omit(invoice, 'attributes')), invoice.attributes)); }), onSubmit: function (selectedThirdPartyType, thirdParties) {
                    return openThirdParty(thirdParties, selectedThirdPartyType, onSubmit);
                } })),
        });
    };
    var allActions = [
        {
            name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_REMINDER,
            function: batchAction('additional_reminder', additionalReminderDetails),
            icon: IconName.SEND,
            category: Categories.RECOVERY,
        },
        {
            name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_FORMAL_NOTICE,
            function: batchAction('formal_notice', formalNoticeDetails),
            icon: IconName.FORMAL_NOTICE,
            category: Categories.RECOVERY,
        },
        {
            name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_THIRD_PARTY,
            function: batchAction('third_party', thirdPartyDetails),
            icon: IconName.LAW,
            category: Categories.RECOVERY,
        },
        {
            name: i18nKeys.PAYMENT_PLAN.TITLE.NEW,
            function: paymentPlan,
            icon: IconName.PAYMENT_BOOK,
            category: Categories.RECOVERY,
        },
        !isConnectorActive
            ? {
                name: i18nKeys.MARK_AS_PAID,
                function: batchAction('paid', aysModal('paid')),
                icon: IconName.CHECK,
                category: Categories.STATUS,
            }
            : null,
        {
            name: i18nKeys.MARK_AS_DISPUTED,
            function: lostOrDisputed('dispute'),
            icon: IconName.ALERT_CIRCLE,
            category: Categories.STATUS,
        },
        {
            name: i18nKeys.REVERT_DISPUTED,
            function: batchAction('revert_dispute', aysModal('undisputed')),
            icon: IconName.BOX_RIBBON,
            category: Categories.STATUS,
        },
        {
            name: i18nKeys.MARK_AS_LOST,
            function: lostOrDisputed('lose'),
            icon: IconName.QUESTION,
            category: Categories.STATUS,
        },
        {
            name: i18nKeys.INVOICE.MOVE_PLAN,
            function: movePlan,
            icon: IconName.REPLACE,
            category: Categories.ASSIGNMENT,
        },
        {
            name: i18nKeys.ASSIGN_ACCOUNT_MANAGER_SHORT,
            function: batchAction('assign', assignToAccManager),
            icon: IconName.USER,
            category: Categories.ASSIGNMENT,
        },
        {
            name: i18nKeys.INVOICE.PAUSE_THE_FOLLOW_UP,
            function: pause,
            icon: IconName.PAUSE,
            category: Categories.FOLLOW_UP,
        },
        {
            name: i18nKeys.INVOICE.RESUME_FOLLUW_UP,
            function: batchAction('unpaused', aysModal('unpaused')),
            icon: IconName.PLAY,
            category: Categories.FOLLOW_UP,
        },
        {
            name: i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.TAKE_OUT_OF_PROCESS,
            function: batchAction('to_be_processed', aysModal('to_be_processed')),
            icon: IconName.ROBOT,
            category: Categories.FOLLOW_UP,
        },
        {
            name: i18nKeys.FORM.TASK.NEW,
            function: batchAction('task', taskDetails),
            icon: IconName.COMMENT,
            category: Categories.ASSIGNMENT,
            disabled: !company.package.can_use_tasks,
        },
        {
            name: i18nKeys.BATCH.ACTIONS.CUSTOM_VARIABLES,
            function: batchAction('custom_variables', customVariablesDetails),
            icon: IconName.PENCIL,
            category: Categories.OTHER,
            disabled: !company.package.can_use_custom_variables,
        },
        {
            name: i18nKeys.IGNORE_LATE_FEES,
            function: batchAction('ignore_late_fees', aysModal('ignore_late_fees')),
            icon: IconName.SIMPLE_REMOVE,
            category: Categories.OTHER,
        },
        !isConnectorActive
            ? {
                name: i18nKeys.DELETE,
                function: batchAction('delete', aysModal('delete')),
                icon: IconName.TRASH_SIMPLE,
                category: Categories.OTHER,
                color: ButtonColor.RED,
            }
            : null,
    ];
    var actions = reject(allActions, isNil);
    return (React.createElement("div", { className: styles('batch-actions-wrapper') }, Object.values(Categories).map(function (category) { return (React.createElement("div", { key: category },
        React.createElement("h5", null, t(i18nKeys.BATCH.CATEGORIES[category])),
        React.createElement("hr", null),
        React.createElement("div", { className: styles('batch-actions') }, actions
            .filter(function (action) { return action.category === category; })
            .map(function (action) { return (React.createElement("div", { className: styles('batch-action', action.color, action.disabled && 'disabled'), onClick: action.function, key: action.name },
            React.createElement(Icon, { name: action.icon }),
            React.createElement("p", null, t(action.name)))); })))); })));
}
